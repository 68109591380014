import React, { ReactNode, createContext, useContext, useState } from 'react';
import { ScheduleGroup } from '../types/schedule/scheduleGroup';
import {
    createDummyScheduleGroup,
    validateScheduleGroupDetails,
} from '../utils';
import { Entity, IdLookupResult } from '../types/schedule/jobLinkID';

type FlashMessage = {
    type: 'success' | 'error';
    operation?: string;
    groupName?: string;
    groupID?: string;
} | null;

export type ScheduleGroupContext = {
    groupData: ScheduleGroup;
    setGroupData: React.Dispatch<React.SetStateAction<ScheduleGroup>>;
    iriToEntityName: {
        [iri: string]: string;
    };
    updateIriToEntityNameMap: (updatedIriToEntityName: { [iri: string]: string }) => void;
    isLoadingIdLookupResults: boolean;
    setIsLoadingIdLookupResults: React.Dispatch<React.SetStateAction<boolean>>;
    removeLinkedId: (idToRemove: string) => void;
    jobConfigSearchInputValue: string;
    setJobConfigSearchInputValue: React.Dispatch<React.SetStateAction<string>>;
    flashMessage: FlashMessage;
    setFlashMessage: React.Dispatch<React.SetStateAction<FlashMessage>>;
    linkEntities: (entitiesToLink: Entity[]) => void;
    idLookupResult: IdLookupResult;
    setIdLookupResult: React.Dispatch<React.SetStateAction<IdLookupResult>>;
    isGroupValid: boolean;
    groupValidationErrors: string[];
};

type ScheduleGroupContextProviderProps = {
    children: ReactNode;
};

export const ScheduleGroupContext = createContext<ScheduleGroupContext | null>(null);

export const ScheduleGroupContextProvider = ({ children }: ScheduleGroupContextProviderProps): JSX.Element => {
    const [groupData, setGroupData] = useState<ScheduleGroup>(createDummyScheduleGroup());
    const [iriToEntityName, setIriToEntityName] = useState<{ [iri: string]: string }>({});
    const [isLoadingIdLookupResults, setIsLoadingIdLookupResults] = useState<boolean>(false);
    const [jobConfigSearchInputValue, setJobConfigSearchInputValue] = useState<string>('');
    const [flashMessage, setFlashMessage] = useState<FlashMessage>(null);
    const [idLookupResult, setIdLookupResult] = useState<IdLookupResult>({
        validIds: [],
        invalidIds: [],
        existingIds: [],
    });

    const { isGroupValid, groupValidationErrors } = validateScheduleGroupDetails(groupData);

    const removeLinkedId = (idToRemove: string) => {
        setGroupData((groupData) => ({
            ...groupData,
            linkedEntities: groupData.linkedEntities.filter((linkedEntity) => linkedEntity.entityBBID !== idToRemove),
        }));
    };

    const updateIriToEntityNameMap = (updatedIriToEntityName: { [iri: string]: string }) => {
        setIriToEntityName({ ...iriToEntityName, ...updatedIriToEntityName });
    };

    const linkEntities = (entitiesToLink: Entity[]) => {
        const dedupedEntitiesToLink: Entity[] = entitiesToLink.filter((entity) => {
            if (groupData.linkedEntities.find((linkedEntity) => linkedEntity.entityBBID === entity.entityBBID)) {
                return false;
            } else {
                return true;
            }
        });

        setGroupData((groupData) => ({
            ...groupData,
            linkedEntities: [...groupData.linkedEntities, ...dedupedEntitiesToLink],
        }));
    };

    return (
        <ScheduleGroupContext.Provider
            value={{
                groupData,
                setGroupData,
                iriToEntityName,
                updateIriToEntityNameMap,
                isLoadingIdLookupResults,
                setIsLoadingIdLookupResults,
                removeLinkedId,
                jobConfigSearchInputValue,
                setJobConfigSearchInputValue,
                flashMessage,
                setFlashMessage,
                linkEntities,
                idLookupResult,
                setIdLookupResult,
                isGroupValid,
                groupValidationErrors
            }}
        >
            {children}
        </ScheduleGroupContext.Provider>
    );
};

export const useScheduleGroupContext = (): ScheduleGroupContext => {
    const scheduleGroupContext = useContext(ScheduleGroupContext);
    if (!scheduleGroupContext) {
        throw new Error('useScheduleGroupContext must be used within a ScheduleGroupContextProvider');
    }
    return scheduleGroupContext;
};
