import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import { AccountTree as RunTreeIcon } from '@mui/icons-material';
import { getJobRunRoute } from '../../utils';

type RunTreeLinkProps = {
    id: string
    docType?: string;
}

const RunTreeLink = ({ id, docType }: RunTreeLinkProps): JSX.Element => {
    const path = getJobRunRoute(id, docType);
    return (
        <Tooltip title={'View Run Tree'}>
            <RouterLink to={path} style={{ textDecoration: 'none' }}>
                <RunTreeIcon color="primary" fontSize="small" />
            </RouterLink>
        </Tooltip>
    );
};

export default RunTreeLink;
