import { ScheduleEvent } from '@webacq/wa-shared-definitions';

export enum JobLinkIdType {
    BBID = 'bbid',
    TICKER = 'ticker',
}

export interface IdLookupResult {
    validIds: string[];
    invalidIds: string[];
    existingIds: string[];
}
export interface BsrEntityLookupResult {
    entityDomainKey: number | string;
    entityDomainName: string;
    entityInstanceIRI: string | null;
    label: string | null | undefined;
    entityTypeIRI?: string | null;
}

export interface BsrErrorResponse {
    code: number;
    message: string;
}

export interface Entity {
    entityBBID: string;
    entityName: string;
    entityInstanceIRI?: string;
    entityInstanceIriURL: string;
    nextEvent: ScheduleEvent | null;
    missingJobConfigIds: string[];
}
