import React, { useEffect, useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { MRT_ColumnDef, MRT_Row, MRT_RowModel, MRT_TableOptions } from 'material-react-table';

import { JobConfig } from '../../types/schedule/scheduleGroup';
import PaginatedTable from '../PaginatedTable/PaginatedTable';
import { formatTimestamp, processJobConfigSearchResult, searchJobConfigsByIds, truncateString } from '../../utils';
import { JobConfigIdMaxLength, JobConfigStartUrlMaxLength } from '../../shared/constants';
import CopyToClipboard from '../CopyToClipboard';
import { useScheduleGroupContext } from '../../context/scheduleGroup';

interface GroupMembersTableProps {
    removeMemberFromGroupByJobConfigId: (jobConfigIdToRemove: string) => void;
    viewOnly: boolean;
}

const GroupMembersTable = (props: GroupMembersTableProps): JSX.Element => {
    const { removeMemberFromGroupByJobConfigId, viewOnly } = props;

    const scheduleGroupContext = useScheduleGroupContext();

    const { groupData } = scheduleGroupContext;

    const [rowModel, setRowModel] = useState<MRT_RowModel<JobConfig>>();

    const [jobConfigIdsFetchedInfo, setJobConfigIdsFetchedInfo] = useState<Set<string>>(new Set());

    const [isBusy, setIsBusy] = useState(false);

    useEffect(() => {
        if (!rowModel) {
            return;
        }

        const jobConfigsDisplayed = rowModel.rows.map((row) => row.original);
        const updatedGroupMembers = [...groupData.members];

        const jobConfigIdsToFetchInfo = jobConfigsDisplayed
            .map((jobConfig) => jobConfig.jobConfigId)
            .filter((jobConfigId) => !jobConfigIdsFetchedInfo.has(jobConfigId));

        const fetchJobConfigInfo = async () => {
            setIsBusy(true);
            const searchRes = await searchJobConfigsByIds(jobConfigIdsToFetchInfo);
            const fetchedJobConfigs = processJobConfigSearchResult(searchRes);
            fetchedJobConfigs.forEach((jobConfig) => {
                const index = updatedGroupMembers.findIndex((member) => member.jobConfigId === jobConfig.jobConfigId);
                if (index !== -1) {
                    Object.assign(updatedGroupMembers[index], jobConfig);
                }
            });
            setJobConfigIdsFetchedInfo(new Set([...jobConfigIdsFetchedInfo, ...jobConfigIdsToFetchInfo]));
            setIsBusy(false);
        };

        fetchJobConfigInfo();
    }, [rowModel]);

    const columns = [
        {
            header: '',
            size: 5,
            id: 'remove-button',
            enableColumnFilter: false,
            enableSorting: false,
            enableColumnActions: false,
            Cell: ({ row }: { row: MRT_Row<JobConfig> }) => (
                <Tooltip title={viewOnly ? 'View Only Mode' : 'Remove from Group'}>
                    <span>
                        <IconButton
                            color="secondary"
                            disableRipple
                            disabled={viewOnly}
                            onClick={() => {
                                removeMemberFromGroupByJobConfigId(row.original.jobConfigId);
                            }}
                        >
                            <Delete />
                        </IconButton>
                    </span>
                </Tooltip>
            ),
        },
        {
            header: 'Job Config ID',
            size: 40,
            accessorKey: 'jobConfigId',
            Cell: ({ row }: { row: MRT_Row<JobConfig> }) => (
                <>
                    <Tooltip title={row.original.jobConfigId}>
                        <a href={`/job-config/${row.original.jobConfigId}`} target="_blank" rel="noopener noreferrer">
                            {truncateString(row.original.jobConfigId, JobConfigIdMaxLength)}
                        </a>
                    </Tooltip>{' '}
                    <CopyToClipboard
                        tooltip="Copy Job Config ID to Clipboard"
                        text2Copy={row.original.jobConfigId}
                        size="small"
                    />
                </>
            ),
        },
        {
            header: 'Job Config Name',
            accessorKey: 'jobConfigName',
            enableColumnFilter: false,
            enableSorting: false,
            enableColumnActions: false,
            Cell: ({ row }: { row: MRT_Row<JobConfig> }) => <>{row.original.jobConfigName}</>,
        },
        {
            header: 'IRI',
            accessorKey: 'iri',
            enableColumnFilter: false,
            enableSorting: false,
            enableColumnActions: false,
            Cell: ({ row }: { row: MRT_Row<JobConfig> }) => (
                <a
                    href={`https://bsm.bloomberg.com/instance/${row.original.iri}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {row.original.iri}
                </a>
            ),
        },
        {
            header: 'Agent ID',
            accessorKey: 'agentId',
            enableColumnFilter: false,
            enableSorting: false,
            enableColumnActions: false,
            Cell: ({ row }: { row: MRT_Row<JobConfig> }) => <>{row.original.agentId}</>,
        },
        {
            header: 'Start URL',
            accessorKey: 'startUrl',
            enableColumnFilter: false,
            enableSorting: false,
            enableColumnActions: false,
            Cell: ({ row }: { row: MRT_Row<JobConfig> }) => {
                const startUrl = row.original.startUrl;
                return (
                    <Tooltip title={startUrl}>
                        <a href={startUrl} target="_blank" rel="noopener noreferrer">
                            {startUrl ? truncateString(startUrl, JobConfigStartUrlMaxLength) : ''}
                        </a>
                    </Tooltip>
                );
            },
        },
        {
            header: 'Created Time',
            filterVariant: 'date-range',
            accessorKey: 'createdDate',
            enableColumnFilter: false,
            enableSorting: false,
            enableColumnActions: false,
            Cell: ({ row }: { row: MRT_Row<JobConfig> }) => <>{formatTimestamp(row.original.createdDate)}</>,
        },
        {
            header: 'Created By',
            accessorKey: 'createdBy',
            enableColumnFilter: false,
            enableSorting: false,
            enableColumnActions: false,
            Cell: ({ row }: { row: MRT_Row<JobConfig> }) => <>{row.original.createdBy}</>,
        },
        {
            header: 'Modified Time',
            filterVariant: 'date-range',
            accessorKey: 'modifiedDate',
            enableColumnFilter: false,
            enableSorting: false,
            enableColumnActions: false,
            Cell: ({ row }: { row: MRT_Row<JobConfig> }) => <>{formatTimestamp(row.original.modifiedDate)}</>,
        },
        {
            header: 'Modified By',
            accessorKey: 'modifiedBy',
            enableColumnFilter: false,
            enableSorting: false,
            enableColumnActions: false,
            Cell: ({ row }: { row: MRT_Row<JobConfig> }) => <>{row.original.modifiedBy}</>,
        },
    ];

    return (
        <div style={{ marginTop: '16px' }}>
            <PaginatedTable
                columns={columns as unknown as MRT_ColumnDef<JobConfig>[]}
                rows={groupData.members}
                setRowModel={setRowModel}
                isBusy={isBusy}
                optionOverrides={
                    {
                        enableColumnResizing: false,
                        renderEmptyRowsFallback: () => (
                            <div style={{ width: '100%', textAlign: 'center', paddingTop: '32px', fontSize: '1rem' }}>
                                No Members Added
                            </div>
                        ),
                    } as unknown as MRT_TableOptions<JobConfig>
                }
            ></PaginatedTable>
        </div>
    );
};

export default GroupMembersTable;
