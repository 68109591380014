import React, { useEffect, useState } from 'react';
import { Tooltip } from '@mui/material';
import { MRT_ColumnDef, MRT_Row, MRT_RowSelectionState, MRT_TableOptions } from 'material-react-table';

import { JobConfig } from '../../types/schedule/scheduleGroup';
import { JobConfigIdMaxLength, JobConfigStartUrlMaxLength } from '../../shared/constants';
import PaginatedTable from '../PaginatedTable/PaginatedTable';
import { formatTimestamp, truncateString } from '../../utils';
import CopyToClipboard from '../CopyToClipboard';

interface JobConfigSearchResultTableProps {
    jobConfigSearchResult: JobConfig[];
    selectedJobConfigs: JobConfig[];
    handleSearchResultSelection: (selected: JobConfig[]) => void;
    isBusy?: boolean;
}

const jobConfigSearchResultTableColumns = [
    {
        header: 'Job Config ID',
        accessorKey: 'jobConfigId',
        Cell: ({ row }: { row: MRT_Row<JobConfig> }) => (
            <>
                <Tooltip title={row.original.jobConfigId}>
                    <a href={`/job-config/${row.original.jobConfigId}`} target="_blank" rel="noopener noreferrer">
                        {truncateString(row.original.jobConfigId, JobConfigIdMaxLength)}
                    </a>
                </Tooltip>{' '}
                <CopyToClipboard
                    tooltip="Copy Job Config ID to Clipboard"
                    text2Copy={row.original.jobConfigId}
                    size="small"
                />
            </>
        ),
    },
    {
        header: 'Job Config Name',
        accessorKey: 'jobConfigName',
    },
    {
        header: 'IRI',
        accessorKey: 'iri',
        Cell: ({ row }: { row: MRT_Row<JobConfig> }) =>
            row.original.iri ? (
                <a
                    href={`https://bsm.bloomberg.com/instance/${row.original.iri}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {row.original.iri}
                </a>
            ) : (
                'Missing IRI'
            ),
    },
    {
        header: 'Agent ID',
        accessorKey: 'agentId',
    },
    {
        header: 'Start URL',
        accessorKey: 'startUrl',
        Cell: ({ row }: { row: MRT_Row<JobConfig> }) => {
            const startUrl = row.original.startUrl;
            return (
                <Tooltip title={startUrl}>
                    <a href={startUrl} target="_blank" rel="noopener noreferrer">
                        {startUrl ? truncateString(startUrl, JobConfigStartUrlMaxLength) : ''}
                    </a>
                </Tooltip>
            );
        },
    },
    {
        header: 'Created Time',
        filterVariant: 'date-range',
        accessorKey: 'createdDate',
        Cell: ({ row }: { row: MRT_Row<JobConfig> }) => <>{formatTimestamp(row.original.createdDate)}</>,
    },
    {
        header: 'Created By',
        accessorKey: 'createdBy',
    },
    {
        header: 'Modified Time',
        filterVariant: 'date-range',
        accessorKey: 'modifiedDate',
        Cell: ({ row }: { row: MRT_Row<JobConfig> }) => <>{formatTimestamp(row.original.modifiedDate)}</>,
    },
    {
        header: 'Modified By',
        accessorKey: 'modifiedBy',
    },
];

const JobConfigSearchResultTable = (props: JobConfigSearchResultTableProps): JSX.Element => {
    const { jobConfigSearchResult, selectedJobConfigs, handleSearchResultSelection, isBusy } = props;

    const initialSearchResultRowSelection: MRT_RowSelectionState = {};

    selectedJobConfigs.forEach((res) => (initialSearchResultRowSelection[res.jobConfigId] = true));

    const [searchResultRowSelection, setSearchResultRowSelection] = useState<MRT_RowSelectionState>(
        initialSearchResultRowSelection
    );

    useEffect(() => {
        const selects = jobConfigSearchResult.filter(
            (searchResult) => searchResultRowSelection[searchResult.jobConfigId]
        );

        handleSearchResultSelection(selects);
    }, [searchResultRowSelection]);

    return (
        <PaginatedTable
            columns={jobConfigSearchResultTableColumns as unknown as MRT_ColumnDef<JobConfig>[]}
            rows={jobConfigSearchResult}
            isBusy={isBusy}
            optionOverrides={
                {
                    getRowId(originalRow: { jobConfigId: string }) {
                        return originalRow.jobConfigId;
                    },
                    enableColumnResizing: false,
                    enableSelectAll: true,
                    enableRowSelection: true,
                    onRowSelectionChange: setSearchResultRowSelection,
                    state: { rowSelection: searchResultRowSelection },
                    localization: {
                        toggleSelectAll: `Select all ${jobConfigSearchResult.length} job configs`,
                        toggleSelectRow: `Select this job config`,
                    },
                    selectAllMode: 'all',
                } as unknown as MRT_TableOptions<JobConfig>
            }
        />
    );
};

export default JobConfigSearchResultTable;
