import React from 'react';
import { Box, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ListRange, Virtuoso } from 'react-virtuoso';
import {
    JobConfigSearchDoc,
    JobDeliverySearchDoc,
    JobSearchDoc,
    AlertNotificationSearchDoc,
    JobErrorSearchDoc,
    JobRunSummarySearchDoc,
    PostProcessingSearchDoc,
} from '@webacq/wa-shared-definitions';
import { HighlightRecords } from '@webacq/wa-shared-servicewrappers';
import { DocTypes } from '../../shared/constants';
import JobConfigSearchDocCard from './JobConfigSearchDocCard';
import JobSearchDocCard from './JobSearchDocCard';
import JobDeliverySearchDocCard from './JobDeliverySearchDocCard';
import AlertNotificationSearchDocCard from './AlertNotificationSearchDocCard';
import JobErrorSearchDocCard from './JobErrorSearchDocCard';
import SystemErrorSearchDocCard from './SystemErrorSearchDocCard';
import { getJobConfigRoute, getJobRunRoute } from '../../utils';
import { JobMode } from '@webacq/wa-shared-definitions';
import { useHistory } from 'react-router-dom';
import PostProcessingSearchDocCard from './PostProcessingSearchDocCard';

const useStyles = makeStyles((theme: Theme) => ({
    resultList: {
        width: '100%',
    },
    resultRow: {
        paddingBottom: theme.spacing(1),
    },
    message: {
        marginLeft: theme.spacing(6),
    },
}));

export type SupportedDocTypes =
    | JobConfigSearchDoc
    | JobSearchDoc
    | JobDeliverySearchDoc
    | AlertNotificationSearchDoc
    | JobErrorSearchDoc
    | JobRunSummarySearchDoc;

interface SearchResultListProps {
    docs?: SupportedDocTypes[];
    highlightData?: HighlightRecords;
    onEndReached?: () => void;
    onVisibleRangeChanged?: (range: ListRange) => void;
    topMostItemIndex?: number;
}

const SearchResultList = (props: SearchResultListProps): JSX.Element => {
    const { docs, highlightData } = props;
    const history = useHistory();
    const classes = useStyles();

    const handleOpenDetails = (jobConfigId: string | undefined, mode?: JobMode | undefined) => {
        if (jobConfigId) {
            history.push(getJobConfigRoute(jobConfigId, mode));
        }
    };

    const _resultRenderer = (result: SupportedDocTypes) => {
        switch (result.type) {
            case DocTypes.JOB_CONFIG: {
                const jobConfig = result as JobConfigSearchDoc;
                return (
                    <JobConfigSearchDocCard
                        key={jobConfig.id}
                        jobConfig={jobConfig}
                        highlightData={highlightData ? highlightData[result.id] : undefined}
                        onOpen={() => handleOpenDetails(jobConfig.jobConfigId, jobConfig.jobMode)}
                    />
                );
            }
            case DocTypes.JOB: {
                const job = result as JobSearchDoc;
                return (
                    <JobSearchDocCard
                        key={job.id}
                        job={job}
                        highlightData={highlightData ? highlightData[job.id] : undefined}
                        onOpen={() => handleOpenDetails(job.jobConfigId, job.jobMode)}
                    />
                );
            }
            case DocTypes.JOB_DLV: {
                const delivery = result as JobDeliverySearchDoc;
                return (
                    <JobDeliverySearchDocCard
                        key={delivery.id}
                        jobDelivery={delivery}
                        highlightData={highlightData ? highlightData[delivery.id] : undefined}
                        onOpen={() => {
                            if (delivery.bbdsTranId) {
                                history.push(getJobRunRoute(delivery.bbdsTranId));
                            }
                        }}
                    />
                );
            }
            case DocTypes.ALERT_NOTIF: {
                const alert = result as AlertNotificationSearchDoc;
                return (
                    <AlertNotificationSearchDocCard
                        key={alert.id}
                        alertNotification={alert}
                        highlightData={highlightData ? highlightData[alert.id] : undefined}
                        onOpen={() => handleOpenDetails(alert.jobConfigId, alert.jobMode)}
                    />
                );
            }
            case DocTypes.JOB_ERR: {
                const jobErr = result as JobErrorSearchDoc;
                return (
                    <JobErrorSearchDocCard
                        key={jobErr.id}
                        error={jobErr}
                        highlightData={highlightData ? highlightData[jobErr.id] : undefined}
                        onOpen={() => {
                            if (jobErr.bbdsTranId) {
                                history.push(getJobRunRoute(jobErr.bbdsTranId, 'JOB_ERR'));
                            }
                        }}
                    />
                );
            }

            case DocTypes.SYS_ERROR: {
                const summary = result as JobRunSummarySearchDoc;
                return (
                    <SystemErrorSearchDocCard
                        key={summary.id}
                        error={summary}
                        highlightData={highlightData ? highlightData[summary.id] : undefined}
                        onOpen={() => handleOpenDetails(summary.jobConfigId, summary.jobMode)}
                    />
                );
            }

            case DocTypes.POST_PROCESSING: {
                const pp = result as PostProcessingSearchDoc;
                return (
                    <PostProcessingSearchDocCard
                        key={pp.id}
                        postproc={pp}
                        highlightData={highlightData ? highlightData[pp.id] : undefined}
                        onOpen={() => handleOpenDetails(pp.jobConfigId, pp.jobMode)}
                    />
                );
            }

            default:
                return <></>;
        }
    };

    if (docs == null) {
        return <></>;
    }

    return docs.length > 0 ? (
        <Virtuoso
            className={classes.resultList}
            totalCount={docs.length}
            //overscan={1000}
            endReached={() => {
                if (props.onEndReached) {
                    props.onEndReached();
                }
            }}
            itemContent={(idx) => (
                <div key={idx} className={classes.resultRow}>
                    {_resultRenderer(docs[idx])}
                </div>
            )}
            rangeChanged={props.onVisibleRangeChanged}
            initialTopMostItemIndex={
                props.topMostItemIndex
                    ? 0 <= props.topMostItemIndex && props.topMostItemIndex < docs.length
                        ? props.topMostItemIndex
                        : 0
                    : 0
            }
        />
    ) : (
        <Box fontStyle="italic">
            <Typography variant="h5" className={classes.message} color="textSecondary" component="span">
                No records found
            </Typography>
        </Box>
    );
};

export default SearchResultList;
