import React, { useEffect, useState } from 'react';
import { Search as SearchIcon } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';
import { TextField, InputAdornment, IconButton, Box, Chip, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import Header from '../components/Header';
import Footer from '../components/Footer';
import ScheduleEventsTable from '../components/ScheduleGroupPage/ScheduleEventsTable';

import wamService from '../services/wamService';

const useStyles = makeStyles(() => {
    return {
        italic: {
            fontStyle: 'italic',
        },
        eventsTableListContainer: {
            width: '98%',
        },
        eventsTableContainer: {
            minHeight: '100px',
            marginTop: '32px',
        },
        eventsTableHeader: {
            fontSize: '1.25rem',
            fontWeight: 700,
        },
    };
});

const EventsList = (): JSX.Element => {
    const classes = useStyles();

    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);

    const [ids, setIds] = useState<{ jobLinkIdType: string; jobLinkIdValue: string }[]>([]);

    const [idToEntityName, setIdToEntityName] = useState<{ [id: string]: string }>({});

    const [idInputValue, setIdInputValue] = useState<string>('');

    const getEntityNames = async (ids: string[]) => {
        const tempIdToEntityName: { [id: string]: string } = {};
        for (const id of ids) {
            const entity = await wamService.lookupEntityByBBID(id);
            tempIdToEntityName[id] = entity.label;
        }
        setIdToEntityName({ ...idToEntityName, ...tempIdToEntityName });
    };

    const processIdInputs = () => {
        const inputIds = idInputValue.split(' ');
        setIds((ids) => [
            ...ids,
            ...inputIds
                .filter((id) => !ids.find((curId) => curId.jobLinkIdValue === id))
                .map((id) => ({ jobLinkIdType: 'bbid', jobLinkIdValue: id })),
        ]);
        setIdInputValue('');
        getEntityNames(inputIds);
    };

    useEffect(() => {
        history.pushState(
            null,
            '',
            `/events-list?jobLinkIdType=bbid${ids.map((id) => `&jobLinkIdValue=${id.jobLinkIdValue}`).join('')}`
        );
    }, [ids]);

    useEffect(() => {
        const jobLinkIdTypeFromParams = searchParams.get('jobLinkIdType');
        const jobLinkIdValuesFromParams = Array.from(new Set(searchParams.getAll('jobLinkIdValue')));
        if (jobLinkIdTypeFromParams && jobLinkIdValuesFromParams.length > 0) {
            setIds(
                jobLinkIdValuesFromParams.map((jobLinkIdValue) => ({
                    jobLinkIdType: jobLinkIdTypeFromParams,
                    jobLinkIdValue,
                }))
            );
            getEntityNames(jobLinkIdValuesFromParams);
        }
    }, []);

    const handleKeyDown = (e: React.KeyboardEvent<HTMLElement>): void => {
        if (e.key === 'Enter') {
            processIdInputs();
        }
    };

    return (
        <Box display="flex" flexDirection="column" height="100vh">
            <Header subtitle="Schedule Events" />
            <Box flexGrow={1} overflow="auto" marginLeft="48px" marginY="20px">
                <h1>Schedule Events</h1>
                <TextField
                    style={{
                        width: '98%',
                    }}
                    variant="outlined"
                    size="medium"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start" disablePointerEvents={!idInputValue}>
                                <IconButton onClick={processIdInputs}>
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    autoComplete="off"
                    placeholder="Enter company BBID to load events"
                    helperText="Enter a BBID or multiple BBIDs (delimited by space or comma)"
                    value={idInputValue}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setIdInputValue(event.target.value);
                    }}
                    onKeyDown={handleKeyDown}
                />
                <div className={classes.eventsTableListContainer}>
                    <Grid container rowSpacing={1} columnSpacing={1} sx={{ marginTop: '16px' }}>
                        {ids.map((id) => (
                            <Grid item key={id.jobLinkIdValue}>
                                <Chip
                                    label={
                                        <p style={{ fontSize: '1.25rem' }}>
                                            {`${id.jobLinkIdType.toUpperCase()}: ${id.jobLinkIdValue}`}
                                        </p>
                                    }
                                    onDelete={() => {
                                        setIds((ids) => ids.filter((id_) => id_.jobLinkIdValue !== id.jobLinkIdValue));
                                    }}
                                />
                            </Grid>
                        ))}
                    </Grid>

                    {ids.map((id) => (
                        <div className={classes.eventsTableContainer} key={id.jobLinkIdValue}>
                            <div className={classes.eventsTableHeader}>
                                Events for <span className={classes.italic}>{idToEntityName[id.jobLinkIdValue]}</span>,
                                BBID: <span className={classes.italic}>{id.jobLinkIdValue}</span>
                            </div>
                            <ScheduleEventsTable
                                eventSource="evts"
                                jobLinkIdType="bbid"
                                jobLinkId={id.jobLinkIdValue}
                            ></ScheduleEventsTable>
                        </div>
                    ))}
                </div>
            </Box>

            <Footer year={2025}></Footer>
        </Box>
    );
};

export default EventsList;
