import React from 'react';
import { makeStyles } from '@mui/styles';
import { useScheduleGroupContext } from '../../context/scheduleGroup';
import { JobLinkIdType } from '../../types/schedule/jobLinkID';
import LoadingIndicator from './LoadingIndicator';
import LinkedIdsTable from './LinkedIdsTable';
import { Stack, Tooltip } from '@mui/material';

const useStyles = makeStyles(() => {
    return {
        sectionSubTitle: {
            fontSize: '1.35rem',
        },
        missingJobsWarning: {
            fontSize: '1.2rem',
            color: 'red',
            alignContent: 'center',
        },
    };
});

interface LinkedIdsSectionProps {
    viewOnly: boolean;
}

const LinkedIdsSection = (props: LinkedIdsSectionProps): JSX.Element => {
    const classes = useStyles();
    const scheduleGroupContext = useScheduleGroupContext();

    const { isLoadingIdLookupResults, groupData } = scheduleGroupContext;

    const { eventSource, linkedEntities } = groupData;

    switch (eventSource) {
        case 'evts':
            return (
                <>
                    <Stack direction="row" spacing={2}>
                        <div className={classes.sectionSubTitle}>
                            {linkedEntities.length > 1 ? 'Linked BBIDs' : 'Linked BBID'}
                        </div>

                        {linkedEntities.filter((entity) => entity.missingJobConfigIds.length).length > 0 && (
                            <Tooltip title="Some jobs for marked companies are missing from the group.">
                                <div className={classes.missingJobsWarning}>Missing Jobs</div>
                            </Tooltip>
                        )}
                    </Stack>
                    {isLoadingIdLookupResults ? (
                        <LoadingIndicator />
                    ) : (
                        <LinkedIdsTable jobLinkIdType={JobLinkIdType.BBID} viewOnly={props.viewOnly}></LinkedIdsTable>
                    )}
                </>
            );

        case 'eco':
            // TODO
            return <>Linked-IDs section for ECO</>;
        case 'day':
            // TODO
            return <>Linked-IDs section for DAY</>;
    }
};

export default LinkedIdsSection;
