import React from 'react';
import { Link, Stack, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CrwlIcon } from '@webacq/wam-ui-components';
import Content from '../components/Content';
import { useAuth } from '../context/auth';
import { getPermissionInfo } from '../utils';
import { GlobalPermissions } from '../shared/constants';
import { config } from '../config';

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        marginTop: theme.spacing(8),
        marginLeft: theme.spacing(8),
    },
    item: {
        marginBottom: theme.spacing(2),
        display: 'flex',
        gap: theme.spacing(2),
    },
}));

const Downloads = (): JSX.Element => {
    const authentication = useAuth();
    const classes = useStyles();

    const canAccessCRWL = () =>
        getPermissionInfo(authentication.authData.permissions || [], GlobalPermissions.CAN_ACCESS_CRWL).hasAccess;

    const items = [] as JSX.Element[];

    if (canAccessCRWL()) {
        items.push(
            <Link
                className={classes.item}
                color="inherit"
                variant="body1"
                target="_blank"
                rel="noopener noreferrer"
                href={`${config.apiUrl}/wam/ui/artifacts/download-latest/win32/x64`}
            >
                <Typography variant="h5">
                    <CrwlIcon fontSize="inherit" />
                </Typography>
                <Typography variant="h5">CRWL UI</Typography>
            </Link>
        );
    }

    return (
        <Content subtitle="Downloads">
            <Stack className={classes.container}>
                {items.length > 0 ? (
                    items
                ) : (
                    <Typography variant="h5" color="secondary">
                        No downloads available
                    </Typography>
                )}
            </Stack>
        </Content>
    );
};

export default Downloads;
