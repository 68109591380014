import React, { useEffect, useState, useCallback } from 'react';
import { Link, RouteComponentProps, useHistory, useLocation } from 'react-router-dom';
import { validate as uuidValidate, version as uuidVersion } from 'uuid';
import {
    RunTree,
    FullTreeIcon,
    BranchIcon,
    LinkHandlerContext,
    LinkHandlerContextType,
    defaultLinkHandlerContext,
} from '@webacq/wam-ui-components';
import Content from '../components/Content';
import searchService from '../services/searchService';
import { getJobConfigRoute, getJobRunRoute, wrapApiCall } from '../utils';
import { useAuth } from '../context/auth';
import {
    GeckoContextTree,
    GeckoModel,
    JobConfigSearchDocRaw,
    JobConfigSearchDoc,
    ProvenanceRecord,
    JobDeliverySearchDocRaw,
    JobDeliverySearchDoc,
    HashObject,
    HashObjectLookup,
} from '@webacq/wa-shared-definitions';
import wamService from '../services/wamService';
import { Alert, Button, LinearProgress, Theme, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { config } from '../config';

const useStyles = makeStyles((theme: Theme) => ({
    horizSpacer: {
        marginLeft: theme.spacing(2),
    },
}));

interface URLParams {
    id: string;
}

const JobRunInfo = (props: RouteComponentProps<URLParams>): JSX.Element => {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);
    let id = '';
    if (props.match.params.id) {
        id = decodeURIComponent(props.match.params.id.split(':')[0]);
    }

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [geckoGraph, setGeckoGraph] = useState<GeckoModel | null>(null);
    const [contextTree, setContextTree] = useState<GeckoContextTree | null>(null);
    const [provRecords, setProvRecords] = useState<ProvenanceRecord[]>([]);
    const [jobConfigId, setJobConfigId] = useState('');
    const [runId, setRunId] = useState('');
    const [jobConfigName, setJobConfigName] = useState('');
    const [isBranch, setIsBranch] = useState(true);
    const [showCompleteTreeBtn, setShowCompleteTreeBtn] = useState(false);

    const authContext = useAuth();

    const LinkHandlerContextValue: LinkHandlerContextType = {
        ...defaultLinkHandlerContext,
        onFetchContent: wamService.fetchBcosContent,
        onAddUrlHash: (hashObj: HashObject) => {
            return wamService.addUrlHash(jobConfigId, hashObj);
        },
        onGetUrlHashes: (objects: HashObjectLookup[]) => {
            return wamService.getUrlHashes(objects);
        },
        getBcosEndpoint: (bcosUrl: string) =>
            `${config.apiUrl}/wam/ui/object?url=${window.encodeURIComponent(bcosUrl)}`,
    };

    const checkIfCompleteTreeExists = async (runId: string) => {
        try {
            const result = await searchService.search<JobDeliverySearchDocRaw, JobDeliverySearchDoc>(
                `run_id_s:${runId} AND is_complete_b:true`,
                'JOB_DLV',
                1
            );
            const doc = result?.data?.docs.length ? result.data.docs[0] : undefined;
            if (doc?.ctxTreeLoc) {
                setShowCompleteTreeBtn(true);
                return;
            }
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        (async () => {
            const jobConfigResult = await searchService.search<JobConfigSearchDocRaw, JobConfigSearchDoc>(
                jobConfigId,
                'JOB_CFG',
                1
            );
            if (!jobConfigResult.data?.docs.length) {
                setErrorMessage(`No data found for job config id ${jobConfigId}`);
                return;
            }

            try {
                const job = jobConfigResult.data.docs[0];
                const parsed = JSON.parse(job.graph);
                setGeckoGraph(parsed as GeckoModel);
                setJobConfigName(job.jobConfigName);
            } catch (e) {
                console.error(`Error parsing gecko graph for ${jobConfigId}`);
                setGeckoGraph(null);
            }
        })();
    }, [jobConfigId]);

    useEffect(() => {
        wrapApiCall(
            authContext,
            async () => {
                setIsLoading(true);
                let ctxTree: GeckoContextTree | undefined;
                let configId: string;
                let rootStepId: string | undefined;
                let jobStartTime: Date | undefined;
                if (id) {
                    const query =
                        uuidValidate(id) && uuidVersion(id) === 4
                            ? `run_id_s:${id} AND is_complete_b:true`
                            : `bbds_tran_id_s:${id}`;

                    const result = await searchService.search<JobDeliverySearchDocRaw, JobDeliverySearchDoc>(
                        query,
                        searchParams.get('docType') || 'JOB_DLV',
                        1
                    );

                    const doc = result?.data?.docs.length ? result.data.docs[0] : undefined;
                    if (!doc || !doc.ctxTreeLoc) {
                        setErrorMessage('Could not find context tree.');
                        return;
                    }

                    ctxTree = await wamService
                        .fetchBcosContent(doc.ctxTreeLoc)
                        .then((res) => res?.content as GeckoContextTree);
                    if (!ctxTree) {
                        setErrorMessage(`Could not load context tree from BCOS, BCOS url: ${doc.ctxTreeLoc}`);
                        return;
                    }

                    configId = doc.jobConfigId || '';
                    rootStepId = doc.provRootStepid || '';
                    jobStartTime = doc.jobStartTime;

                    if (!configId || !rootStepId || !jobStartTime) {
                        setErrorMessage('Could not load data fields.');
                        return;
                    }

                    setJobConfigId(configId);
                    setIsBranch(!doc.isComplete);
                    setRunId(doc.runId || '');

                    if (!doc.isComplete && doc.runId) {
                        // Don't await this, it can be done in the background
                        checkIfCompleteTreeExists(doc.runId);
                    }
                } else {
                    setErrorMessage('Must provide runId or bbds transaction ID');
                    return;
                }

                try {
                    const children = [ctxTree];
                    while (children.length) {
                        const node = children.pop();
                        if (node?.['body'] && typeof node['body'] === 'string') {
                            node['body'] = JSON.parse(node['body']);
                        }
                        if (node?.children) {
                            children.push(...node.children);
                        }
                    }
                    setContextTree(ctxTree);
                } catch (e) {
                    console.error('Error parsing contextTree result', e);
                    setContextTree(null);
                }

                if (rootStepId) {
                    try {
                        const records = await getProvenanceRecords(rootStepId, jobStartTime);
                        console.log({ records });
                        setProvRecords(records);
                    } catch (e) {
                        console.error(`Error fetching provenance records ${rootStepId} ${jobStartTime}`);
                        setProvRecords([]);
                    }
                }
            },
            (e: Error) => {
                setErrorMessage('Error loading data');
                setContextTree(null);
                setGeckoGraph(null);
                setProvRecords([]);
                console.error(e);
            },
            () => setIsLoading(false)
        );
    }, [id]);

    const getProvenanceRecords = useCallback(
        async (rootStepId: string, jobStartTime?: Date): Promise<ProvenanceRecord[]> => {
            try {
                const params: Record<string, string> = { rootStepId };
                if (jobStartTime) {
                    params['start'] = jobStartTime.toISOString();
                }
                const resp = await wamService.queryProvenance(params);
                return resp.records;
            } catch (e) {
                console.error(e);
            }
            return [];
        },
        []
    );

    const subtitle = (
        <>
            Run Tree:
            {contextTree && geckoGraph && (
                <>
                    <span className={classes.horizSpacer} />
                    <Tooltip title="View job config details">
                        <Link to={getJobConfigRoute(jobConfigId)}>{jobConfigName || jobConfigId}</Link>
                    </Tooltip>
                    <span className={classes.horizSpacer} />
                    <span>
                        {isBranch ? (
                            <>
                                <Tooltip title="Branch Delivery">
                                    <span style={{ verticalAlign: 'bottom' }}>
                                        <BranchIcon />
                                    </span>
                                </Tooltip>
                                <span className={classes.horizSpacer} />
                                {showCompleteTreeBtn && (
                                    <Button
                                        variant="outlined"
                                        endIcon={<FullTreeIcon />}
                                        onClick={() => history.push(getJobRunRoute(runId))}
                                    >
                                        Switch to Complete Tree
                                    </Button>
                                )}
                            </>
                        ) : (
                            <Tooltip title="Complete Run">
                                <span>
                                    <FullTreeIcon />
                                </span>
                            </Tooltip>
                        )}
                    </span>
                </>
            )}
        </>
    );

    return (
        <Content subtitle={subtitle} quickLinkTail={`/R ${id}`}>
            {isLoading && <LinearProgress />}
            {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
            {contextTree && geckoGraph && (
                <LinkHandlerContext.Provider value={LinkHandlerContextValue}>
                    <RunTree
                        contextTree={contextTree as GeckoContextTree}
                        geckoGraph={geckoGraph}
                        provenanceRecords={provRecords}
                    />
                </LinkHandlerContext.Provider>
            )}
        </Content>
    );
};

export default JobRunInfo;
