import React from 'react';
import { IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Close } from '@mui/icons-material';

import { useScheduleGroupContext } from '../../context/scheduleGroup';

const useStyles = makeStyles(() => {
    return {
        italic: {
            fontStyle: 'italic',
        },
        flashMessageContainerSuccess: {
            backgroundColor: 'green',
            display: 'flex',
            justifyContent: 'space-between',
        },
        flashMessageContainerError: {
            backgroundColor: 'red',
            display: 'flex',
            justifyContent: 'space-between',
        },
        flashMessage: {
            width: '100%',
            height: '48px',
            lineHeight: '48px',
            paddingLeft: '32px',
            paddingRight: '32px',
            backgroundColor: 'green',
            fontSize: '1.25rem',
            fontWeight: 500,
        },
    };
});

const FlashMessage = (): JSX.Element => {
    const classes = useStyles();
    const scheduleGroupContext = useScheduleGroupContext();

    const { flashMessage, setFlashMessage } = scheduleGroupContext;

    const CloseButton = () => (
        <IconButton
            disableRipple
            onClick={() => {
                setFlashMessage(null);
            }}
        >
            <Close />
        </IconButton>
    );

    return flashMessage ? (
        flashMessage.type === 'success' ? (
            <div className={classes.flashMessageContainerSuccess}>
                <div className={classes.flashMessage}>
                    {flashMessage.operation} schedule group{' '}
                    <span className={classes.italic}>{flashMessage.groupName}</span>, group_id:{' '}
                    <span className={classes.italic}>{flashMessage.groupID}</span>
                </div>
                <CloseButton />
            </div>
        ) : (
            <div className={classes.flashMessageContainerError}>
                <div className={classes.flashMessage}>An error occurred</div>
                <CloseButton />
            </div>
        )
    ) : (
        <></>
    );
};

export default FlashMessage;
