import React, { useEffect } from 'react';
import { JobSearchDoc, ScheduleEvent } from '@webacq/wa-shared-definitions';
import { CollapsibleCard } from '@webacq/wam-ui-components';
import { Box, CircularProgress, Theme, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
    formatTimestamp,
    parseJobSchedule,
    parseScheduleTemplateTimeValueInMilliseconds,
    parseScheduleTemplateTimeValueInSeconds,
    truncateString,
} from '../../utils';
import JobStatus from '../JobStatus';
import PaginatedTable from '../PaginatedTable/PaginatedTable';
import CardTitle from './CardTitle';
import { PaginatedTableColumn } from '../PaginatedTable/types';
import { ScheduleRecord } from '../../types/jobSchedule';
import { ScheduleTemplateIntervalTimeUnit } from '../../types/schedule/scheduleTemplate';
import wamService from '../../services/wamService';
import { MRT_Row, MRT_TableOptions } from 'material-react-table';

const useStyles = makeStyles((theme: Theme) => ({
    contentBox: {
        paddingLeft: theme.spacing(4),
    },
    scheduleTableContainer: {
        marginBottom: theme.spacing(2),
    },
}));

interface JobSchedulesCardProps {
    collapsed: boolean;
    onCollapsedChanged?: (collapsed: boolean, ctrlKey: boolean) => void;
    jobs: JobSearchDoc[];
    jobVersionMapping: Record<string, number>;
}

const simpleScheduleColumns = [
    {
        header: 'Job Config Version',
        accessorKey: 'jobConfigVersion',
    },
    {
        header: 'Job Mode',
        accessorKey: 'jobMode',
    },
    {
        header: 'Run Location',
        accessorKey: 'runLocation',
    },
    {
        header: 'Status',
        accessorKey: 'status',
        accessorFn: function getStatusChip(rowData: ScheduleRecord) {
            return <JobStatus status={rowData.status} />;
        },
    },
    {
        header: 'Schedule Type',
        accessorKey: 'scheduleDetails.scheduleType',
    },
    {
        header: 'Schedule',
        accessorKey: 'description',
        accessorFn: function getSchedule(rowData: ScheduleRecord) {
            const scheduleDetails = rowData.scheduleDetails;
            return scheduleDetails.scheduleType === 'simple-schedule' ? (
                <Tooltip title={rowData.rawDescription}>
                    <>{scheduleDetails.readableText} </>
                </Tooltip>
            ) : (
                <></>
            );
        },
    },
] as PaginatedTableColumn<ScheduleRecord>[];

const evtsScheduleColumns = [
    {
        header: '',
        id: 'dummy-parent-column',
        columns: [
            {
                header: 'Job Config Version',
                accessorKey: 'jobConfigVersion',
            },
            {
                header: 'Job Mode',
                accessorKey: 'jobMode',
            },
            {
                header: 'Run Location',
                accessorKey: 'runLocation',
            },
            {
                header: 'Status',
                accessorKey: 'status',
                accessorFn: function getStatusChip(rowData: ScheduleRecord) {
                    return <JobStatus status={rowData.status} />;
                },
            },
            {
                header: 'Schedule Type',
                accessorKey: 'scheduleDetails.scheduleType',
            },
            {
                header: 'Schedule Group',
                filterVariant: 'select',
                accessorKey: 'scheduleDetails.scheduleGroupName',
                Cell: ({ row }: { row: MRT_Row<ScheduleRecord> }) =>
                    row.original.scheduleDetails.scheduleType === 'evts' &&
                    row.original.scheduleDetails.scheduleGroupId && (
                        <Tooltip title={row.original.scheduleDetails.scheduleGroupId}>
                            <a
                                href={`/edit-schedule-group/${row.original.scheduleDetails.scheduleGroupId}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {truncateString(row.original.scheduleDetails.scheduleGroupName, 20)}
                            </a>
                        </Tooltip>
                    ),
            },
            {
                header: 'Schedule',
                accessorFn: function getSchedule(rowData: ScheduleRecord) {
                    return (
                        rowData.scheduleDetails.scheduleType === 'evts' && (
                            <Box>
                                {rowData.scheduleDetails.intervals.map((interval, index) => {
                                    let displayValue;
                                    let displayUnit;

                                    const parsedFromMs = parseScheduleTemplateTimeValueInMilliseconds(
                                        interval.intervalMS
                                    );

                                    if (parsedFromMs.unit === ScheduleTemplateIntervalTimeUnit.SECOND) {
                                        const parsedFromSeconds = parseScheduleTemplateTimeValueInSeconds(
                                            parsedFromMs.value
                                        );
                                        displayValue = parsedFromSeconds.value;
                                        displayUnit = parsedFromSeconds.unit.toLowerCase();
                                    } else {
                                        displayValue = parsedFromMs.value;
                                        displayUnit = parsedFromMs.unit.toLowerCase();
                                    }

                                    return (
                                        <Box key={index}>
                                            From{' '}
                                            <Tooltip
                                                title={formatTimestamp(new Date(interval.datetimeStart))}
                                                placement="left-end"
                                            >
                                                <Box
                                                    component="span"
                                                    color="primary.main"
                                                    sx={{ textDecoration: 'underline', textUnderlineOffset: '2px' }}
                                                >
                                                    {interval.datetimeStart}
                                                </Box>
                                            </Tooltip>{' '}
                                            to{' '}
                                            <Tooltip
                                                title={formatTimestamp(new Date(interval.datetimeEnd))}
                                                placement="top"
                                            >
                                                <Box
                                                    component="span"
                                                    color="primary.main"
                                                    sx={{ textDecoration: 'underline', textUnderlineOffset: '2px' }}
                                                >
                                                    {interval.datetimeEnd}
                                                </Box>
                                            </Tooltip>
                                            , every{' '}
                                            <Box component="span" color="secondary.main">
                                                {displayValue} {displayValue > 1 ? `${displayUnit}s` : displayUnit}
                                            </Box>
                                            ,{' '}
                                            <Tooltip title="Estimated number of runs" placement="top">
                                                <Box
                                                    component="span"
                                                    color="info.main"
                                                    sx={{ textDecoration: 'underline', textUnderlineOffset: '2px' }}
                                                >
                                                    {Math.floor(
                                                        (new Date(interval.datetimeEnd).getTime() -
                                                            new Date(interval.datetimeStart).getTime()) /
                                                            interval.intervalMS
                                                    )}
                                                </Box>
                                            </Tooltip>{' '}
                                            runs
                                        </Box>
                                    );
                                })}
                            </Box>
                        )
                    );
                },
            },
        ],
    },
    {
        header: 'EVTS Event',
        columns: [
            {
                header: 'Event Description',
                accessorKey: 'scheduleDetails.scheduleEvent.eventBlob.eventDescription',
            },
            {
                header: 'Event Start Time',
                accessorKey: 'scheduleDetails.scheduleEvent.eventStartDate',
                accessorFn(originalRow) {
                    if (originalRow.scheduleDetails && originalRow.scheduleDetails.scheduleType === 'evts') {
                        const scheduleEvent = originalRow.scheduleDetails.scheduleEvent;
                        if (!scheduleEvent) {
                            return '';
                        }
                        const eventStartDate = scheduleEvent.eventStartDate;
                        return (
                            <Tooltip title={formatTimestamp(new Date(eventStartDate))} placement="top-start">
                                <Box component="span" sx={{ textDecoration: 'underline', textUnderlineOffset: '2px' }}>
                                    {eventStartDate}
                                </Box>
                            </Tooltip>
                        );
                    }
                    return '';
                },
            },
        ],
    },
] as PaginatedTableColumn<ScheduleRecord>[];

const JobSchedulesCard = (props: JobSchedulesCardProps): JSX.Element => {
    const classes = useStyles();

    const [simpleSchedules, setSimpleSchedules] = React.useState<ScheduleRecord[]>([]);

    const [evtsSchedules, setEvtsSchedules] = React.useState<ScheduleRecord[]>([]);
    const [loadingEvtsSchedulesDetails, setLoadingEvtsSchedulesDetails] = React.useState<boolean | null>(null);

    const totalNumSchedules = simpleSchedules.length + evtsSchedules.length;

    useEffect(() => {
        const tmpSimpleSchedules: ScheduleRecord[] = [];
        const tmpEvtsSchedules: ScheduleRecord[] = [];

        const parsedJobSchedules = props.jobs.map((job) => {
            return parseJobSchedule(job, props.jobVersionMapping);
        });

        for (const parsedJobSchedule of parsedJobSchedules) {
            if (parsedJobSchedule) {
                if (parsedJobSchedule.scheduleDetails.scheduleType === 'simple-schedule') {
                    tmpSimpleSchedules.push(parsedJobSchedule);
                }
            }
        }
        setSimpleSchedules(tmpSimpleSchedules);

        // For EVTS schedules, we need to fetch additional details for each event
        const fetchEvtsSchedulesDetails = async () => {
            setLoadingEvtsSchedulesDetails(true);
            // Create a map to store eventId to ScheduleEvent mapping
            // This is to avoid fetching the same event multiple times
            // when multiple schedules share the same event
            const eventIdToEventMap: Record<string, ScheduleEvent> = {};
            for (const parsedJobSchedule of parsedJobSchedules) {
                if (parsedJobSchedule && parsedJobSchedule.scheduleDetails.scheduleType === 'evts') {
                    const scheduleId = parsedJobSchedule.scheduleId;
                    if (scheduleId) {
                        const scheduleInfo = await wamService.getScheduleInfoByScheduleId(scheduleId);
                        if (scheduleInfo && scheduleInfo.scheduleGroupId && scheduleInfo.eventId) {
                            parsedJobSchedule.scheduleDetails.scheduleGroupId = scheduleInfo.scheduleGroupId;
                            const scheduleGroupInfo = await wamService.getScheduleGroup(scheduleInfo.scheduleGroupId);
                            parsedJobSchedule.scheduleDetails.scheduleGroupName =
                                scheduleGroupInfo?.scheduleGroup.groupName || scheduleInfo.scheduleGroupId;
                            const eventId = scheduleInfo.eventId;
                            if (eventIdToEventMap[eventId]) {
                                parsedJobSchedule.scheduleDetails.scheduleEvent = eventIdToEventMap[eventId];
                            } else {
                                const event = await wamService.getScheduleEventByEventId(eventId);
                                if (event) {
                                    eventIdToEventMap[eventId] = event;
                                    parsedJobSchedule.scheduleDetails.scheduleEvent = event;
                                }
                            }
                            tmpEvtsSchedules.push(parsedJobSchedule);
                        }
                    }
                }
            }
            setLoadingEvtsSchedulesDetails(false);
            setEvtsSchedules(tmpEvtsSchedules);
        };

        fetchEvtsSchedulesDetails();
    }, [props.jobs]);

    return (
        <CollapsibleCard
            collapsed={props.collapsed}
            onCollapsedChanged={(collapsed, qualifiers) =>
                props.onCollapsedChanged && props.onCollapsedChanged(collapsed, qualifiers.ctrl)
            }
            title={<CardTitle title="Schedules" recordCount={totalNumSchedules} collapsedMode={props.collapsed} />}
        >
            {simpleSchedules.length > 0 || evtsSchedules.length > 0 ? (
                <>
                    {simpleSchedules.length > 0 && (
                        <div className={classes.scheduleTableContainer}>
                            <span style={{ fontSize: '1.25rem', marginLeft: '16px' }}>Simple Schedules</span>
                            <PaginatedTable columns={simpleScheduleColumns} rows={simpleSchedules} />
                        </div>
                    )}
                    {loadingEvtsSchedulesDetails ? (
                        <Box display="flex" justifyContent="center" marginTop="16px">
                            <CircularProgress />
                        </Box>
                    ) : (
                        evtsSchedules.length > 0 && (
                            <div className={classes.scheduleTableContainer}>
                                <span style={{ fontSize: '1.25rem', marginLeft: '16px' }}>EVTS Schedules</span>
                                <PaginatedTable
                                    columns={evtsScheduleColumns}
                                    rows={evtsSchedules}
                                    optionOverrides={
                                        {
                                            enableFacetedValues: true,
                                        } as MRT_TableOptions<ScheduleRecord>
                                    }
                                />
                            </div>
                        )
                    )}
                </>
            ) : (
                <Box className={classes.contentBox} fontStyle="italic">
                    <Typography variant="body2">No schedules found</Typography>
                </Box>
            )}
        </CollapsibleCard>
    );
};

export default JobSchedulesCard;
