import { DateTime } from 'luxon';
import {
    JobConfigSearchDoc,
    JobConfigSearchDocRaw,
    JobMode,
    JobSearchDoc,
    ScheduleEventSource,
} from '@webacq/wa-shared-definitions';
import { config } from './config';
import { AuthContextType } from './context/auth';
import { ServiceError } from './services/api';
import { PermissionInfo } from './services/wamService';
import {
    DocTypes,
    GlobalPermissions,
    MinimumIntervalValue,
    QueryStrings,
    RunLocationMapping,
    SOLRFieldMapping,
    TimeConversionRate,
} from './shared/constants';
import {
    PaginatedTableFilterSpec,
    PaginatedTableSortingSpec,
    PaginatedTableDateFilterValue,
    PaginatedTableFilterDateOperator,
} from './components/PaginatedTable/types';
import {
    DatetimeEventScheduleTemplatePhaseType,
    EventScheduleTemplate,
    EventScheduleTemplateIntervalOnlyStep,
    EventScheduleTemplateWindowStep,
    ScheduleEventType,
    ScheduleTemplateIntervalTimeUnit,
    ScheduleTemplateOffsetTimeUnit,
} from './types/schedule/scheduleTemplate';
import { JobConfig, ScheduleGroup } from './types/schedule/scheduleGroup';
import searchService from './services/searchService';
import { SearchResult } from '@webacq/wa-shared-servicewrappers';
import { Entity, BsrEntityLookupResult } from './types/schedule/jobLinkID';
import { ScheduleRecord } from './types/jobSchedule';

export const pluralSuffix = (count?: number, suffix = 's', alternate = ''): string =>
    count !== 1 ? suffix : alternate;

const getEnvTail = (): string => {
    switch (config.env) {
        case 'PROD':
            return '/PROD';
        case 'BETA':
            return '/BETA';
        case 'DEV':
        case 'LOCAL':
            return '/DEV';
    }

    return '';
};

export const launchMnemonic = (mnemonic: string, tail?: string): void => {
    if (mnemonic) {
        window.open(`bbg://screens/${mnemonic}${tail ? ' ' + tail : ''}`);
    }
};

export const launchCRWL = (jobConfigId?: string): void => {
    if (jobConfigId) {
        launchMnemonic('CRWL', `${getEnvTail()} /JC ${jobConfigId}`);
    }
};

export const launchNSN = (suid?: string): void => {
    if (suid) {
        launchMnemonic('NSN', suid);
    }
};

export const createQuickLink = (tail: string): string => {
    return `{CRWL HUB ${getEnvTail()} ${tail}}`;
};

export const launchUrl = (url?: string): void => {
    if (url) {
        // prepend with scheme if not already present
        if (!/^\s*https*:\/\//i.test(url)) {
            url = `https://${url}`;
        }
        window.open(url);
    }
};

export const getSearchRoute = (searchterm: string, skipFacets?: string[]): string => {
    let url = '/';
    if (searchterm) {
        url += `?${QueryStrings.SEARCH}=${searchterm}`;
    }
    if (skipFacets) {
        skipFacets.forEach((facet) => (url += `&skipfacets=${facet}`));
    }
    return url;
};

export const getJobConfigRoute = (jobConfigId?: string, jobMode?: JobMode): string => {
    let url = `/job-config/${jobConfigId || ''}`;
    if (jobMode) {
        url += `?${QueryStrings.JOB_MODE}=${jobMode}`;
    }
    return url;
};

export function getJobRunRoute(id: string, docType?: string): string {
    let path = `/run/${encodeURIComponent(id)}`;
    if (docType) {
        path += `?docType=${docType}`;
    }
    return path;
}

export function getHTMLDiffRoute(jobConfigId: string, deliveryUrl: string, acquisitionTime?: Date): string {
    let url = `/htmldiff/${jobConfigId}/${encodeURIComponent(deliveryUrl)}`;
    if (acquisitionTime) {
        url += `?${QueryStrings.ACQUISITION_TIME}=${acquisitionTime.getTime()}`;
    }
    return url;
}

export const getAlertConfigRoute = (jobConfigId?: string, alertConfigId?: string): string => {
    let url = `/alert-config/${jobConfigId || ''}`;
    if (alertConfigId) {
        url += `?${QueryStrings.ALERT_CONFIG_ID}=${alertConfigId}`;
    }
    return url;
};

export const getObjectViewerRoute = (objectUrl: string, hideNewTabIcon = false): string => {
    let url = `/object?url=${encodeURIComponent(objectUrl)}`;
    if (hideNewTabIcon) {
        url += `&hideNewTab`;
    }
    return url;
};

export const getHumioLink = (logConfigName: string, query: string, start?: Date, end?: Date): string => {
    let stage = '';
    switch (config.env) {
        case 'PROD':
            stage = 'prod';
            break;
        case 'BETA':
            stage = 'beta';
            break;
        case 'DEV':
        case 'LOCAL':
            stage = 'dev';
            break;
    }

    const url = new URL('https://humio.prod.bloomberg.com/wam/search');
    url.searchParams.append(
        'query',
        `* | #logConfigName=${logConfigName} #parentCluster=bpaas | bpaasStage=${stage}-* ${query}`
    );
    url.searchParams.append('fullscreen', 'false');
    url.searchParams.append('live', 'false');
    if (start) {
        url.searchParams.append('start', start.getTime() + '');
    }
    if (end) {
        url.searchParams.append('end', end.getTime() + '');
    }

    return url.toString();
};

export const parseJobSchedule = (
    job: JobSearchDoc,
    jobVersionMapping: Record<string, number>
): ScheduleRecord | null => {
    if (!job.schedule) {
        return null;
    }

    const parsedSchedule = JSON.parse(job.schedule);

    const res = {
        jobConfigVersion: (jobVersionMapping && jobVersionMapping[job.jobMode || '']) || 0,
        jobMode: job.jobMode || '<??>',
        status: job.jobStatus,
        runLocation: RunLocationMapping[job.locationName || ''] || job.locationName || '',
        rawDescription: job.schedule,
        scheduleId: job.scheduleId,
    };

    if (parsedSchedule.timepoint) {
        const readable = jobSchedule2text(job);

        return {
            ...res,
            scheduleDetails: {
                scheduleType: 'simple-schedule',
                readableText: readable,
            },
        };
    } else if (parsedSchedule.scheduleType === 'evts') {
        const intervals = parsedSchedule.schedule;

        return {
            ...res,
            scheduleDetails: {
                scheduleType: 'evts',
                scheduleGroupId: '',
                scheduleGroupName: '',
                intervals,
                timezone: parsedSchedule.timezone,
            },
        };
    }

    return null;
};

export const jobSchedule2text = (job: JobSearchDoc): string => {
    if (!job.schedule) {
        return 'never';
    }

    const parsedSchedule = JSON.parse(job.schedule);

    if (parsedSchedule.timepoint) {
        const rrule = parsedSchedule.timepoint.icalendar_rrule;

        const frequencyMapping: Record<string, string> = {
            YEARLY: 'year',
            MONTHLY: 'month',
            WEEKLY: 'week',
            DAILY: 'day',
            HOURLY: 'hour',
            MINUTELY: 'minute',
            SECONDLY: 'second',
        };

        const frequency = frequencyMapping[rrule.freq];

        let readable =
            rrule.interval && rrule.interval !== 1 ? `every ${rrule.interval} ${frequency}s` : `every ${frequency}`;

        if (rrule.dtstart) {
            readable += ` starting at ${formatTimestamp(new Date(rrule.dtstart))}`;
        }
        if (rrule.until) {
            readable += ` until ${formatTimestamp(new Date(rrule.until))}`;
        }

        return readable;
    }

    return '';
};

export async function wrapApiCall<T>(
    authContext: AuthContextType,
    apiFunc: () => T,
    errorFunc?: (e: Error) => void,
    cleanupFunc?: () => void
): Promise<T | undefined> {
    return wrapApiCall2(authContext, undefined, apiFunc, errorFunc, cleanupFunc);
}

export async function wrapApiCall2<T, RT>(
    authContext: AuthContextType,
    defaultReturnVal: RT,
    apiFunc: () => T,
    errorFunc?: (e: Error) => void,
    cleanupFunc?: () => void
): Promise<T | RT> {
    try {
        return await apiFunc();
    } catch (e) {
        if (e instanceof ServiceError) {
            console.error(e);
            if (e.code === 401) {
                authContext.setAuthData({ isAuthenticated: false });
            }
        }

        errorFunc && errorFunc(e as Error);
    } finally {
        cleanupFunc && cleanupFunc();
    }

    return defaultReturnVal;
}

interface Permission {
    hasAccess: boolean;
    statusMessage?: string;
}

export const getPermissionInfo = (permissions: PermissionInfo[], level: GlobalPermissions): Permission => {
    const permission = permissions.find((permission) => permission.action === level);
    if (permission) {
        return {
            hasAccess: permission.permission === 'allowed',
            statusMessage: permission.message,
        };
    }

    // we should never end up here, but let's cover our ...
    return {
        hasAccess: false,
        statusMessage: 'Unauthorized to access...',
    };
};

export const formatDuration = (durationInMilliseconds: number): string => {
    if (durationInMilliseconds < 1000) {
        return `${durationInMilliseconds} ms`;
    }

    let seconds = Math.floor(durationInMilliseconds / 1000);

    if (seconds < 60) {
        // if less than a minute, and we have milliseconds
        // then display with 1 decimal (s.ms)
        const milliseconds = durationInMilliseconds - seconds * 1000;
        if (milliseconds !== 0) {
            return `${(durationInMilliseconds / 1000).toFixed(1)} seconds`;
        }
    }

    // omit milliseconds if runtime is > 1 min...

    let minutes = 0;
    let hours = 0;

    minutes = Math.floor(seconds / 60);
    seconds -= minutes * 60;

    if (minutes >= 60) {
        hours = Math.floor(minutes / 60);
        minutes -= hours * 60;
    }

    const hoursTxt = hours > 0 ? `${hours} hour${pluralSuffix(hours)}` : '';
    const minutesTxt = minutes > 0 ? `${minutes} minute${pluralSuffix(minutes)}` : '';
    const secondsTxt = seconds > 0 ? `${seconds} second${pluralSuffix(seconds)}` : '';

    return `${hoursTxt} ${minutesTxt} ${secondsTxt}`.trim();
};

export const formatTimestamp = (timestamp?: Date): string => {
    if (timestamp) {
        const dt = DateTime.fromJSDate(timestamp);

        if (dt.isValid) {
            return `${dt.toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)} ${dt.toFormat('ZZZZ')}`;
        }
    }

    return '';
};

export const formatLargeNumber = (num: number): string => {
    const origNum = num;
    const base = 1000;

    if (num >= 0) {
        for (const unit of ['', 'k', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y']) {
            if (num < base) {
                return `${Math.floor(num)}${unit}`;
            }

            num /= base;
        }
    }

    return `${origNum}`;
};

export const addEllipsis = (str: string, maxLen: number): string => {
    if (str.length <= maxLen) {
        return str;
    }

    const compLen = Math.floor((maxLen - 3) / 2);
    return `${str.substring(0, compLen)}…${str.substring(str.length - compLen)}`;
};

export const createSolrFilter = (filterSpec?: PaginatedTableFilterSpec): string => {
    return (filterSpec || [])
        .reduce((prev, curr) => {
            const solrField = SOLRFieldMapping[curr.field];

            if (solrField && curr.value !== undefined) {
                switch (curr.type) {
                    case 'numeric':
                        prev.push(`${solrField}:${curr.value}`);
                        break;

                    case 'boolean':
                        prev.push(`${solrField}:${curr.value ? 'true' : 'false'}`);
                        break;

                    case 'date':
                        {
                            const value = curr.value as PaginatedTableDateFilterValue;
                            if (value.date) {
                                const startOfDay = new Date(
                                    value.date.getFullYear(),
                                    value.date.getMonth(),
                                    value.date.getDate(),
                                    0,
                                    0,
                                    0,
                                    0
                                );

                                const endOfDay = new Date(
                                    value.date.getFullYear(),
                                    value.date.getMonth(),
                                    value.date.getDate(),
                                    23,
                                    59,
                                    59,
                                    999
                                );

                                switch (value.operator) {
                                    case PaginatedTableFilterDateOperator.Before:
                                        prev.push(`${solrField}:[* TO ${startOfDay.toISOString()}]`);
                                        break;

                                    case PaginatedTableFilterDateOperator.On:
                                        prev.push(
                                            `${solrField}:[${startOfDay.toISOString()} TO ${endOfDay.toISOString()}]`
                                        );
                                        break;

                                    case PaginatedTableFilterDateOperator.After:
                                        prev.push(`${solrField}:[${endOfDay.toISOString()} TO *]`);
                                        break;
                                }
                            }
                        }
                        break;

                    case 'string':
                        prev.push(`${solrField}:*${curr.value}*`);
                        break;

                    // case 'texts': {
                    //     const texts = filter.value as PageableTableFilterTextsValue;
                    //     const condition = texts.map((text) => `${solrField}:${text}`).join(' OR ');

                    //     if (condition) {
                    //         return `${prev} AND (${condition})`;
                    //     } else {
                    //         return prev;
                    //     }
                    // }
                }
            }

            return prev;
        }, [] as string[])
        .join(' AND ');
};

export const createSolrSorting = (sortSpec?: PaginatedTableSortingSpec): string => {
    return (sortSpec || [])
        .reduce((prev, curr) => {
            if (curr.field && curr.direction) {
                const solrField = SOLRFieldMapping[curr.field];
                if (solrField) {
                    prev.push(`${solrField} ${curr.direction}, id ${curr.direction}`);
                }
            }

            return prev;
        }, [] as string[])
        .join(',');
};

export const truncateString = (s: string, maxLen: number): string => {
    return s.length > maxLen ? `${s.slice(0, maxLen)}...` : s;
};

export const createDummyScheduleTemplate = (): EventScheduleTemplate => {
    return {
        datetime: {
            beforeRelease: { intervals: [] },
            afterRelease: { intervals: [] },
        },
        datetimeRange: {
            beforeRelease: { intervals: [] },
            duringRelease: {
                intervalUnit: ScheduleTemplateIntervalTimeUnit.SECOND,
                intervalValue: 100,
                allowOverlappedRun: false,
                intervalMS: 100000,
            },
            afterRelease: { intervals: [] },
        },
    };
};

export const createDummyScheduleGroup = (eventType?: string): ScheduleGroup => ({
    groupId: '',
    groupName: '',
    eventSource: (eventType as ScheduleEventSource) || 'evts',
    linkedEntities: [],
    members: [],
    template: createDummyScheduleTemplate(),
    created: new Date(),
    createdBy: '',
    eventLinkAttr: {
        bbid: '',
        ticker: '',
        group_id: '',
    },
});

export const createPlaceholderScheduleTemplateRow = (): EventScheduleTemplateWindowStep => ({
    startTimeOffsetValue: 0,
    startTimeOffsetUnit: ScheduleTemplateOffsetTimeUnit.SECOND,
    fromSeconds: 0,
    endTimeOffsetValue: 0,
    endTimeOffsetUnit: ScheduleTemplateOffsetTimeUnit.SECOND,
    toSeconds: 0,
    intervalValue: 100,
    intervalUnit: ScheduleTemplateIntervalTimeUnit.SECOND,
    intervalMS: 100000,
    allowOverlappedRun: false,
});

export const createPlaceholderScheduleTemplateIntervalOnlyStep = (): EventScheduleTemplateIntervalOnlyStep => ({
    intervalValue: 100,
    intervalUnit: ScheduleTemplateIntervalTimeUnit.SECOND,
    intervalMS: 100000,
    allowOverlappedRun: false,
});

export const searchJobConfigs = async (query: string): Promise<JobConfigSearchDoc[]> => {
    const searchResult = await searchService.search<JobConfigSearchDocRaw, JobConfigSearchDoc>(
        `(${query}) AND is_routed_b:true`,
        DocTypes.JOB_CONFIG
    );

    return searchResult.data?.docs || [];
};

export const searchJobConfigsByIris = async (iris: string[]): Promise<JobConfigSearchDoc[]> => {
    // run the search in batches of 10, to avoid exceeding SOLR's max clause number limit
    const batchSize = 10;
    const searchResultPromises: Promise<SearchResult<JobConfigSearchDoc>>[] = [];
    for (let batchNum = 0; batchNum * batchSize < iris.length; ++batchNum) {
        const irisBatch = iris.slice(batchNum * batchSize, (batchNum + 1) * batchSize);

        searchResultPromises.push(
            searchService.search<JobConfigSearchDocRaw, JobConfigSearchDoc>(
                `(${irisBatch.join(' OR ')}) AND is_routed_b:true`,
                DocTypes.JOB_CONFIG
            )
        );
    }

    const searchResults = await Promise.all(searchResultPromises);

    return searchResults
        .map((searchResult) => searchResult.data?.docs || [])
        .reduce((acc, cur) => {
            return acc.concat(cur);
        }, []);
};

export const searchJobConfigsByIds = async (jobConfigIds: string[]): Promise<JobConfigSearchDoc[]> => {
    // run the search in batches of size batchSize, to avoid exceeding URL length limit
    const batchSize = 50;
    const searchResultPromises: Promise<SearchResult<JobConfigSearchDoc>>[] = [];
    for (let batchNum = 0; batchNum * batchSize < jobConfigIds.length; ++batchNum) {
        const jobConfigIdClause = jobConfigIds
            .slice(batchNum * batchSize, (batchNum + 1) * batchSize)
            .map((jobConfigId) => `job_config_id_s:${jobConfigId}`)
            .join(' OR ');

        searchResultPromises.push(
            searchService.search<JobConfigSearchDocRaw, JobConfigSearchDoc>(
                `(${jobConfigIdClause}) AND is_routed_b:true`,
                DocTypes.JOB_CONFIG
            )
        );
    }

    const searchResults = await Promise.all(searchResultPromises);

    return searchResults
        .map((searchResult) => searchResult.data?.docs || [])
        .reduce((acc, cur) => {
            return acc.concat(cur);
        }, []);
};

export const processEntityLookupResult = (lookupResult: BsrEntityLookupResult): Entity => {
    return {
        entityBBID: lookupResult.entityDomainKey.toString(),
        entityName: lookupResult.label ?? '',
        entityInstanceIRI: lookupResult.entityInstanceIRI ? lookupResult.entityInstanceIRI.split('/').at(-1) : '',
        entityInstanceIriURL: lookupResult.entityInstanceIRI ?? '',
        nextEvent: null,
        missingJobConfigIds: [],
    };
};

export const processScheduleGroupDetailsBeforeSaving = (groupData: ScheduleGroup) => {
    const processTemplateWindowSteps = (steps: EventScheduleTemplateWindowStep[]) => {
        return steps.map((step) => ({
            fromSeconds: step.startTimeOffsetValue * TimeConversionRate.toSeconds[step.startTimeOffsetUnit],
            intervalMS: step.intervalValue * TimeConversionRate.toMilliseconds[step.intervalUnit],
            toSeconds: step.endTimeOffsetValue * TimeConversionRate.toSeconds[step.endTimeOffsetUnit],
            allowOverlappedRun: step.allowOverlappedRun,
        }));
    };

    const processTemplateIntervalOnlyStep = (step: EventScheduleTemplateIntervalOnlyStep) => {
        return {
            intervalMS: step.intervalValue * TimeConversionRate.toMilliseconds[step.intervalUnit],
            allowOverlappedRun: step.allowOverlappedRun,
        };
    };

    return {
        groupName: groupData.groupName,
        eventSource: groupData.eventSource,
        eventLinkAttr: JSON.stringify({ bbid: groupData.linkedEntities.map((entity) => entity.entityBBID) }),
        jobConfigIds: groupData.members.map((member) => member.jobConfigId),
        scheduleTemplate: JSON.stringify({
            datetime: {
                beforeRelease: {
                    intervals: processTemplateWindowSteps(groupData.template.datetime.beforeRelease.intervals),
                },
                afterRelease: {
                    intervals: processTemplateWindowSteps(groupData.template.datetime.afterRelease.intervals),
                },
            },
            datetimeRange: {
                beforeRelease: {
                    intervals: processTemplateWindowSteps(groupData.template.datetimeRange.beforeRelease.intervals),
                },
                duringRelease: processTemplateIntervalOnlyStep(groupData.template.datetimeRange.duringRelease),
                afterRelease: {
                    intervals: processTemplateWindowSteps(groupData.template.datetimeRange.afterRelease.intervals),
                },
            },
        }),
    };
};

export const processJobConfigSearchResult = (jobConfigSearchResults: JobConfigSearchDoc[]): JobConfig[] => {
    return jobConfigSearchResults.map((searchResult: JobConfigSearchDoc) => {
        let iri = '';

        const matched = JSON.stringify(JSON.parse(searchResult.graph)).match(
            /"about":"<https:\/\/bsm.bloomberg.com\/instance\/([A-Z0-9]+)>/
        );

        if (matched && matched.length === 2) {
            iri = matched[1];
        }

        return {
            jobConfigId: searchResult.jobConfigId,
            jobConfigName: searchResult.jobConfigName,
            agentId: searchResult.agentId,
            createdDate: searchResult.jcCrAt,
            createdBy: searchResult.jcCrByName,
            modifiedDate: searchResult.jcUpAt,
            modifiedBy: searchResult.jcUpByName,
            startUrl: searchResult.jobStartUrl ? searchResult.jobStartUrl[0] || '' : '',
            iri,
        };
    });
};

export const groupJobConfigsByIri = (jobConfigs: JobConfig[]): { [iri: string]: JobConfig[] } => {
    const jobConfigsGroupedByIri: { [iri: string]: JobConfig[] } = {};

    for (const jobConfig of jobConfigs) {
        const iri = jobConfig.iri;

        if (!iri) continue;

        jobConfigsGroupedByIri[iri] = jobConfigsGroupedByIri[iri]
            ? [...jobConfigsGroupedByIri[iri], jobConfig]
            : [jobConfig];
    }

    return jobConfigsGroupedByIri;
};

export const validateScheduleTemplateStepOffsetValue = (
    offsetValue: number
): { isValid: boolean; helperText: string | null } => {
    if (offsetValue < 0) {
        return {
            isValid: false,
            helperText: 'Non-negative value only ',
        };
    } else {
        return {
            isValid: true,
            helperText: null,
        };
    }
};

export const validateScheduleTemplateStepIntervalValue = (
    intervalValue: number,
    intervalUnit: 'Hour' | 'Minute' | 'Second' | 'Millisecond'
): { isValid: boolean; helperText: string | null } => {
    if (intervalValue <= 0) {
        return {
            isValid: false,
            helperText: 'Positive value only',
        };
    } else {
        if (intervalValue * TimeConversionRate.toMilliseconds[intervalUnit] < MinimumIntervalValue) {
            return {
                isValid: false,
                helperText: 'Interval must be longer than 10 seconds',
            };
        } else {
            return {
                isValid: true,
                helperText: '',
            };
        }
    }
};

export const validateScheduleTemplateStepEndTime = (
    step: EventScheduleTemplateWindowStep,
    phaseType: 'beforeRelease' | 'afterRelease'
): { isValid: boolean; helperText: string | null } => {
    if (
        phaseType === 'beforeRelease' &&
        step.endTimeOffsetValue * TimeConversionRate.toMilliseconds[step.endTimeOffsetUnit] >
            step.startTimeOffsetValue * TimeConversionRate.toMilliseconds[step.startTimeOffsetUnit]
    ) {
        return {
            isValid: false,
            helperText: 'End time should be later than start time',
        };
    }

    if (
        phaseType === 'afterRelease' &&
        step.endTimeOffsetValue * TimeConversionRate.toMilliseconds[step.endTimeOffsetUnit] <
            step.startTimeOffsetValue * TimeConversionRate.toMilliseconds[step.startTimeOffsetUnit]
    ) {
        return {
            isValid: false,
            helperText: 'End time should be later than start time',
        };
    }

    return {
        isValid: true,
        helperText: null,
    };
};

export const validateScheduleGroupDetails = (
    groupData: ScheduleGroup | null
): { isGroupValid: boolean; groupValidationErrors: string[] } => {
    if (!groupData) {
        return { isGroupValid: true, groupValidationErrors: [] };
    }

    const scheduleTypes: ScheduleEventType[] = ['datetime', 'datetimeRange'];
    const scheduleTemplateCommonPhases: DatetimeEventScheduleTemplatePhaseType[] = ['beforeRelease', 'afterRelease'];

    const rules = [
        // group name cannot be empty
        {
            rule: (groupData: ScheduleGroup) => groupData.groupName.length > 0,
            errorMessage: 'Group name cannot be empty.',
        },
        // should be linked to at least one entity
        {
            rule: (groupData: ScheduleGroup) => groupData.linkedEntities.length > 0,
            errorMessage: 'Group should be linked to at least one company.',
        },
        {
            // schedule template steps' startTime offset values must be non-negative
            rule: (groupData: ScheduleGroup) => {
                for (const scheduleType of scheduleTypes) {
                    for (const phaseType of scheduleTemplateCommonPhases) {
                        for (const step of groupData.template[scheduleType][phaseType].intervals) {
                            const { isValid } = validateScheduleTemplateStepOffsetValue(step.startTimeOffsetValue);
                            if (!isValid) return false;
                        }
                    }
                }
                return true;
            },
            errorMessage: 'Found invalid value in schedule template start time offset.',
        },
        {
            // schedule template steps' endTime offset values must be non-negative
            rule: (groupData: ScheduleGroup) => {
                for (const scheduleType of scheduleTypes) {
                    for (const phaseType of scheduleTemplateCommonPhases) {
                        for (const step of groupData.template[scheduleType][phaseType].intervals) {
                            const { isValid } = validateScheduleTemplateStepOffsetValue(step.endTimeOffsetValue);
                            if (!isValid) return false;
                        }
                    }
                }
                return true;
            },
            errorMessage: 'Found invalid value in schedule template end time offset.',
        },
        {
            // schedule template steps' intervals must be non-negative and at least 10 seconds
            rule: (groupData: ScheduleGroup) => {
                for (const scheduleType of scheduleTypes) {
                    for (const phaseType of scheduleTemplateCommonPhases) {
                        for (const step of groupData.template[scheduleType][phaseType].intervals) {
                            const { isValid } = validateScheduleTemplateStepIntervalValue(
                                step.intervalValue,
                                step.intervalUnit
                            );
                            if (!isValid) return false;
                        }
                    }
                }
                const duringReleaseStep = groupData.template.datetimeRange.duringRelease;
                const { isValid } = validateScheduleTemplateStepIntervalValue(
                    duringReleaseStep.intervalValue,
                    duringReleaseStep.intervalUnit
                );

                return isValid;
            },
            errorMessage: 'Found invalid value in schedule template interval.',
        },
        {
            // schedule template steps' startTime should be no later than endTime
            rule: (groupData: ScheduleGroup) => {
                for (const scheduleType of scheduleTypes) {
                    for (const phaseType of scheduleTemplateCommonPhases) {
                        for (const step of groupData.template[scheduleType][phaseType].intervals) {
                            const { isValid } = validateScheduleTemplateStepEndTime(step, phaseType);
                            if (!isValid) return false;
                        }
                    }
                }
                return true;
            },
            errorMessage: 'Found invalid value in schedule template end time offset.',
        },
    ];

    return rules.reduce(
        (acc, cur) => {
            if (!cur.rule(groupData)) {
                acc.isGroupValid = false;
                acc.groupValidationErrors.push(cur.errorMessage);
            }
            return acc;
        },
        { isGroupValid: true, groupValidationErrors: [] } as { isGroupValid: boolean; groupValidationErrors: string[] }
    );
};

export const deepCompareGroupData = (groupDataCopy: ScheduleGroup, groupData: ScheduleGroup): boolean => {
    const plainFields: (keyof ScheduleGroup)[] = ['groupName', 'eventSource'];
    for (const field of plainFields) {
        if (groupData[field] !== groupDataCopy[field]) {
            return false;
        }
    }

    const objectFields: (keyof ScheduleGroup)[] = ['members', 'template'];
    for (const field of objectFields) {
        if (JSON.stringify(groupData[field]) !== JSON.stringify(groupDataCopy[field])) {
            return false;
        }
    }

    const groupDataCopyLinkedEntityIds = groupDataCopy.linkedEntities.map((entity) => entity.entityBBID);
    const groupDataLinkedEntityIds = groupData.linkedEntities.map((entity) => entity.entityBBID);
    if (JSON.stringify(groupDataCopyLinkedEntityIds) !== JSON.stringify(groupDataLinkedEntityIds)) {
        return false;
    }

    return true;
};

export const parseScheduleTemplateTimeValueInSeconds = (
    valueInSeconds: number
): { value: number; unit: ScheduleTemplateOffsetTimeUnit } => {
    if (valueInSeconds === 0) {
        return { value: valueInSeconds, unit: ScheduleTemplateOffsetTimeUnit.SECOND };
    }
    if (valueInSeconds % 3600 === 0) {
        return { value: valueInSeconds / 3600, unit: ScheduleTemplateOffsetTimeUnit.HOUR };
    } else if (valueInSeconds % 60 === 0) {
        return { value: valueInSeconds / 60, unit: ScheduleTemplateOffsetTimeUnit.MINUTE };
    }
    return { value: valueInSeconds, unit: ScheduleTemplateOffsetTimeUnit.SECOND };
};

export const parseScheduleTemplateTimeValueInMilliseconds = (
    valueInMilliseconds: number
): { value: number; unit: ScheduleTemplateIntervalTimeUnit } => {
    if (valueInMilliseconds === 0) {
        return { value: valueInMilliseconds, unit: ScheduleTemplateIntervalTimeUnit.MILLISECOND };
    }
    if (valueInMilliseconds % 1000 === 0) {
        return { value: valueInMilliseconds / 1000, unit: ScheduleTemplateIntervalTimeUnit.SECOND };
    }

    return { value: valueInMilliseconds, unit: ScheduleTemplateIntervalTimeUnit.MILLISECOND };
};
