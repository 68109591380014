import React from 'react';
import {
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TextField,
    Select,
    SelectChangeEvent,
    Switch,
    MenuItem,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import {
    ScheduleTemplateIntervalTimeUnit,
    EventScheduleTemplateIntervalOnlyStep,
} from '../../types/schedule/scheduleTemplate';
import { MinimumIntervalValue, TimeConversionRate } from '../../shared/constants';

const useStyles = makeStyles(() => {
    return {
        inputGroupContainer: {
            marginBottom: '32px',
        },
        noStepsDefinedLabel: {
            fontSize: '1rem',
            marginTop: '16px',
            marginBottom: '16px',
        },
    };
});

interface ScheduleTemplateIntervalOnlyStepProps {
    duringReleaseStep: EventScheduleTemplateIntervalOnlyStep;
    setDuringReleaseStep: (duringReleaseStep: EventScheduleTemplateIntervalOnlyStep) => void;
    viewOnly: boolean;
}

const ScheduleTemplateIntervalOnlyStepInput = (props: ScheduleTemplateIntervalOnlyStepProps): JSX.Element => {
    const classes = useStyles();

    const { duringReleaseStep, setDuringReleaseStep, viewOnly } = props;

    const intervalValue = duringReleaseStep.intervalValue;
    const intervalUnit = duringReleaseStep.intervalUnit;
    const allowOverlappedRun = duringReleaseStep.allowOverlappedRun;

    const validateIntervalValue = () => {
        if (intervalValue <= 0) {
            return {
                isValid: false,
                helperText: 'Positive value only',
            };
        } else {
            if (intervalValue * TimeConversionRate.toMilliseconds[intervalUnit] < MinimumIntervalValue) {
                return {
                    isValid: false,
                    helperText: 'Interval must be longer than 10 seconds',
                };
            } else {
                return {
                    isValid: true,
                    helperText: null,
                };
            }
        }
    };

    const intervalValueValidationResult = validateIntervalValue();

    const handleInput = (inputValue: string) => {
        console.log('handling input', inputValue);
        setDuringReleaseStep({
            ...duringReleaseStep,
            intervalValue: Number(inputValue),
        });
    };

    return (
        <div className={classes.inputGroupContainer}>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Interval</TableCell>
                            <TableCell>Unit</TableCell>
                            <TableCell align="left" width="170px">
                                Allow Overlapped Run
                            </TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                <TextField
                                    disabled={viewOnly}
                                    fullWidth
                                    type="number"
                                    size="small"
                                    error={!intervalValueValidationResult.isValid}
                                    helperText={intervalValueValidationResult.helperText}
                                    InputProps={{
                                        style: { fontSize: '1rem' },
                                        inputProps: {
                                            min: MinimumIntervalValue / TimeConversionRate.toMilliseconds[intervalUnit],
                                        },
                                    }}
                                    value={intervalValue}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        handleInput(event.target.value);
                                    }}
                                />
                            </TableCell>

                            <TableCell>
                                <Select
                                    disabled={viewOnly}
                                    fullWidth
                                    defaultValue={ScheduleTemplateIntervalTimeUnit.SECOND}
                                    value={intervalUnit}
                                    onChange={(event: SelectChangeEvent) => {
                                        setDuringReleaseStep({
                                            ...duringReleaseStep,
                                            intervalUnit: event.target.value as ScheduleTemplateIntervalTimeUnit,
                                        });
                                    }}
                                >
                                    {Object.values(ScheduleTemplateIntervalTimeUnit).map((unit) => (
                                        <MenuItem value={unit} key={unit}>
                                            {Math.abs(intervalValue) > 1 ? `${unit}s` : unit}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </TableCell>
                            <TableCell align="center">
                                <Switch
                                    disabled={viewOnly}
                                    color="secondary"
                                    checked={allowOverlappedRun}
                                    onChange={() => {
                                        setDuringReleaseStep({
                                            ...duringReleaseStep,
                                            allowOverlappedRun: !duringReleaseStep.allowOverlappedRun,
                                        });
                                        setTimeout(() => {
                                            // we don't want to allow overlapped run at this time, turn the user back if they they click on the switch
                                            alert('Allowing overlapped run is not currently supported.');
                                            setDuringReleaseStep({
                                                ...duringReleaseStep,
                                                allowOverlappedRun: duringReleaseStep.allowOverlappedRun,
                                            });
                                        }, 0);
                                    }}
                                ></Switch>
                            </TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default ScheduleTemplateIntervalOnlyStepInput;
