import React, { useEffect, useState } from 'react';
import { IconButton, TextField, LinearProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Search } from '@mui/icons-material';

import { Entity } from '../../types/schedule/jobLinkID';
import wamService from '../../services/wamService';
import { useScheduleGroupContext } from '../../context/scheduleGroup';
import { processEntityLookupResult } from '../../utils';

const useStyles = makeStyles(() => {
    return {
        inputContainer: {
            display: 'flex',
            flexDirection: 'row',
            marginTop: '16px',
            marginBottom: '16px',
        },
        bbidLookupSuccessMessage: {
            color: '#66BB6A',
            fontSize: '1rem',
            paddingLeft: '16px',
        },
        bbidLookupWarningMessage: {
            color: '#FFA028',
            fontSize: '1rem',
            paddingLeft: '16px',
        },
        bbidLookupErrorMessage: {
            color: '#F44336',
            fontSize: '1rem',
            paddingLeft: '16px',
        },
        messageList: {
            marginTop: '4px',
            paddingLeft: '16px',
        },
    };
});

const LinkEventsByBBID = (): JSX.Element => {
    const classes = useStyles();
    const scheduleGroupContext = useScheduleGroupContext();

    const {
        groupData,
        linkEntities,
        isLoadingIdLookupResults,
        setIsLoadingIdLookupResults,
        idLookupResult,
        setIdLookupResult,
    } = scheduleGroupContext;
    const [bbidInputValue, setBbidInputValue] = useState<string>('');

    const [isBbidInputValid, setIsBbidInputValid] = useState<boolean>(true);

    const foundUnknownBbids = idLookupResult.invalidIds.length > 0;

    useEffect(() => {
        if (!bbidInputValue) {
            setIsBbidInputValid(true);
            return;
        }

        const validationHandler = setTimeout(() => {
            const isValid = bbidInputValue.trim().length ? /^[\d ,]+$/.test(bbidInputValue) : true;

            setIsBbidInputValid(isValid);
        }, 500);

        return () => clearTimeout(validationHandler);
    }, [bbidInputValue]);

    const handleBbidLookup = async () => {
        setIsLoadingIdLookupResults(true);
        setIdLookupResult((idLookupResult) => ({ ...idLookupResult, invalidIds: [] }));

        const inputBbids = new Set(bbidInputValue.trim().match(/\d+/g));

        const existingBbids: string[] = [];
        const bbidsToLookup: string[] = [];
        for (const inputBbid of inputBbids) {
            if (groupData.linkedEntities.find((entity) => entity.entityBBID === inputBbid)) {
                existingBbids.push(inputBbid);
            } else {
                bbidsToLookup.push(inputBbid);
            }
        }

        const entitiesToBeLinked: Entity[] = [];
        const invalidIdsFound: string[] = [];

        for (const bbidToLookup of bbidsToLookup) {
            if (bbidToLookup) {
                const lookupResult = await wamService.lookupEntityByBBID(bbidToLookup);
                if (lookupResult.isError) {
                    const unknownBBID = lookupResult.entityDomainKey;
                    invalidIdsFound.push(unknownBBID);
                } else {
                    const entityToLink = processEntityLookupResult(lookupResult);
                    const nextEvent = await wamService.getNextEventByBBID(bbidToLookup);
                    if (nextEvent) {
                        entityToLink.nextEvent = nextEvent;
                    }
                    entitiesToBeLinked.push(entityToLink);
                }
            }
        }

        linkEntities(entitiesToBeLinked);

        setIdLookupResult((idLookupResult) => ({
            ...idLookupResult,
            validIds: entitiesToBeLinked.map((id) => id.entityBBID),
            invalidIds: invalidIdsFound,
            existingIds: existingBbids,
        }));

        invalidIdsFound.length > 0 ? setBbidInputValue(invalidIdsFound.join(' ')) : setBbidInputValue('');
        setIsLoadingIdLookupResults(false);
    };

    const getInputFieldHelperText = () => {
        if (foundUnknownBbids) {
            return `Found Unknown BBID${idLookupResult.invalidIds.length > 1 ? 's' : ''}`;
        }
        if (!isBbidInputValid) {
            return 'Invalid Input';
        }
        return 'Enter a BBID or multiple BBIDs (delimited by space or comma)';
    };

    return (
        <>
            {isLoadingIdLookupResults && <LinearProgress sx={{ marginTop: '16px' }}></LinearProgress>}
            <div className={classes.inputContainer}>
                <TextField
                    variant="outlined"
                    size="small"
                    label="Look up BBIDs"
                    inputProps={{
                        style: { fontSize: '1.25rem' },
                    }}
                    autoComplete="off"
                    value={bbidInputValue}
                    error={!isBbidInputValid || foundUnknownBbids}
                    helperText={getInputFieldHelperText()}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setBbidInputValue(event.target.value);
                    }}
                    onKeyDown={(e: React.KeyboardEvent<HTMLElement>): void => {
                        if (e.key === 'Enter') {
                            handleBbidLookup();
                        }
                    }}
                    sx={{
                        width: '50%',
                    }}
                />
                <IconButton
                    color="primary"
                    disabled={bbidInputValue.length === 0 || !isBbidInputValid}
                    size="large"
                    disableRipple
                    sx={{ alignSelf: 'start' }}
                    onClick={handleBbidLookup}
                >
                    <Search />
                </IconButton>
                {idLookupResult.validIds.length > 0 && (
                    <div className={classes.bbidLookupSuccessMessage}>
                        {idLookupResult.validIds.length > 1 ? 'Successfully Linked BBIDs' : 'Successfully Linked BBID'}:
                        <ul className={classes.messageList}>
                            {idLookupResult.validIds.map((successfulId) => (
                                <li key={successfulId}>{successfulId}</li>
                            ))}
                        </ul>
                    </div>
                )}
                {idLookupResult.existingIds.length > 0 && (
                    <div className={classes.bbidLookupWarningMessage}>
                        {idLookupResult.existingIds.length > 1 ? 'BBIDs already linked' : 'BBID already linked'}:
                        <ul className={classes.messageList}>
                            {idLookupResult.existingIds.map((unknownId) => (
                                <li key={unknownId}>{unknownId}</li>
                            ))}
                        </ul>
                    </div>
                )}
                {idLookupResult.invalidIds.length > 0 && (
                    <div className={classes.bbidLookupErrorMessage}>
                        {idLookupResult.invalidIds.length > 1 ? 'Unknown BBIDs' : 'Unknown BBID'}:
                        <ul className={classes.messageList}>
                            {idLookupResult.invalidIds.map((unknownId) => (
                                <li key={unknownId}>{unknownId}</li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
        </>
    );
};

export default LinkEventsByBBID;
