import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
    Box,
    CircularProgress,
    FormControlLabel,
    MenuItem,
    Select,
    Switch,
    TextField,
    Typography,
} from '@mui/material';
import { MRT_ColumnDef, MRT_Row, MRT_TableOptions } from 'material-react-table';
import { ScheduleEvent } from '@webacq/wa-shared-definitions';

import PaginatedTable from '../PaginatedTable/PaginatedTable';
import wamService from '../../services/wamService';
import { TimeConversionRate } from '../../shared/constants';
import { formatTimestamp } from '../../utils';

const useStyles = makeStyles(() => {
    return {
        eventsSectionContainer: {
            marginTop: '16px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
        },
        eventsFiltersContainer: {
            marginBottom: '16px',
        },
        eventsTableContainer: {
            width: '100%',
        },
        noEventsBox: {
            width: '100%',
            textAlign: 'center',
            backgroundColor: '#242424',
            fontSize: '1.25rem',
            paddingTop: '48px',
            paddingBottom: '48px',
        },
    };
});

interface ScheduleEventsTableProps {
    eventSource: string;
    jobLinkIdType: string;
    jobLinkId: string;
}

const scheduleEventsTableColumns = (jobLinkIdType: string) => [
    {
        header: 'Event ID',
        accessorKey: 'eventId',
    },
    {
        header: 'Event Description',
        accessorKey: 'eventBlob.eventDescription',
    },
    {
        header: 'Source',
        accessorKey: 'eventSource',
        filterVariant: 'select',
        size: 20,
    },
    {
        header: jobLinkIdType,
        accessorKey: 'jobLinkIdValue',
        size: 10,
    },
    {
        header: 'Status',
        accessorKey: 'status',
        filterVariant: 'select',
        size: 20,
    },
    {
        header: 'Start Time',
        filterVariant: 'date-range',
        accessorKey: 'eventStartDate',
        Cell: ({ row }: { row: MRT_Row<ScheduleEvent> }) => (
            <>{formatTimestamp(new Date(row.original.eventStartDate))}</>
        ),
    },
    {
        header: 'End Time',
        filterVariant: 'date-range',
        accessorKey: 'eventEndDate',
        Cell: ({ row }: { row: MRT_Row<ScheduleEvent> }) => (
            <>{formatTimestamp(new Date(row.original.eventEndDate))}</>
        ),
    },
];

type EventFilterUnit = 'Hour' | 'Day' | 'Month' | 'Year';

const eventFilterUnitToValueOptions: { [k in EventFilterUnit]: number[] } = {
    Hour: [1, 2, 3, 6, 12, 24],
    Day: [1, 2, 7, 30],
    Month: [1, 3, 6, 12],
    Year: [1, 2, 3, 5, 10],
};

const getPastEvents = (events: ScheduleEvent[]) =>
    events.filter((event) => new Date(event.eventStartDate) < new Date());

const getAllFutureEvents = (events: ScheduleEvent[]) =>
    events.filter((event) => new Date(event.eventStartDate) >= new Date());

const filterFutureEvents = (events: ScheduleEvent[], filterValue: number, filterUnit: EventFilterUnit) => {
    const timeNow = new Date().getTime();
    const timeOffset = filterValue * TimeConversionRate.toMilliseconds[filterUnit];
    return events.filter((event) => {
        const eventStartTime = new Date(event.eventStartDate).getTime();
        return eventStartTime >= timeNow && eventStartTime <= timeNow + timeOffset;
    });
};

const ScheduleEventsTable = (props: ScheduleEventsTableProps): JSX.Element => {
    const classes = useStyles();

    const { eventSource, jobLinkIdType, jobLinkId } = props;

    const [eventsList, setEventsList] = useState<ScheduleEvent[]>([]);

    const [loadingEvents, setLoadingEvents] = useState<boolean>(false);
    const [filterType, setFilterType] = useState<'showPast' | 'future' | null>(null);
    const [eventFilterUnit, setEventFilterUnit] = useState<EventFilterUnit>('Day');
    const [eventFilterValueOptions, setEventFilterValueOptions] = useState<number[]>(
        eventFilterUnitToValueOptions[eventFilterUnit]
    );
    const eventFilterUnitOptions: EventFilterUnit[] = ['Hour', 'Day', 'Month', 'Year'];
    const [eventFilterValue, setEventFilterValue] = useState<number>(eventFilterValueOptions[0]);
    const [eventFilterCustomValueInput, setEventFilterCustomValueInput] = useState<string>('');
    const [openEventFilterValueSelect, setOpenEventFilterValueSelect] = useState<boolean>(false);

    const [filteredEventsList, setFilteredEventsList] = useState<ScheduleEvent[]>([]);

    useEffect(() => {
        switch (filterType) {
            case 'showPast':
                setFilteredEventsList((filteredEventsList) => [...getPastEvents(eventsList), ...filteredEventsList]);
                break;
            case 'future':
                setFilteredEventsList(filterFutureEvents(eventsList, eventFilterValue, eventFilterUnit));
                break;
            case null:
                setFilteredEventsList(getAllFutureEvents(eventsList));
        }
    }, [filterType, eventFilterValue, eventFilterUnit]);

    useEffect(() => {
        if (!eventFilterUnitToValueOptions[eventFilterUnit].includes(eventFilterValue)) {
            setEventFilterValue(eventFilterUnitToValueOptions[eventFilterUnit][0]);
        }
    }, [eventFilterUnit]);

    useEffect(() => {
        const fetchScheduleEventList = async () => {
            setLoadingEvents(true);
            const fetchedEventsList = await wamService.getScheduleEventList(eventSource, jobLinkIdType, jobLinkId);
            setEventsList(fetchedEventsList);
            setFilteredEventsList(getAllFutureEvents(fetchedEventsList));
            setLoadingEvents(false);
        };

        fetchScheduleEventList();
    }, [eventSource, jobLinkIdType, jobLinkId]);

    return (
        <div className={classes.eventsSectionContainer}>
            {loadingEvents ? (
                <CircularProgress />
            ) : eventsList.length > 0 ? (
                <div className={classes.eventsTableContainer}>
                    <div className={classes.eventsFiltersContainer}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={filterType === 'showPast'}
                                    onChange={() =>
                                        setFilterType((filterType) => {
                                            if (filterType === 'showPast') {
                                                return null;
                                            } else {
                                                return 'showPast';
                                            }
                                        })
                                    }
                                />
                            }
                            label="Show past events"
                            sx={{ marginLeft: '4px' }}
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={filterType === 'future'}
                                    onChange={() => {
                                        setFilterType((filterType) => {
                                            if (filterType === 'future') {
                                                return null;
                                            } else {
                                                return 'future';
                                            }
                                        });
                                    }}
                                />
                            }
                            label="Show events happening in the next"
                            sx={{ marginLeft: '4px' }}
                        />
                        <Select
                            value={eventFilterValue}
                            onChange={(e) => {
                                setEventFilterValue(Number(e.target.value));
                            }}
                            size="small"
                            open={openEventFilterValueSelect}
                            onOpen={() => setOpenEventFilterValueSelect(true)}
                            onClose={() => setOpenEventFilterValueSelect(false)}
                        >
                            {eventFilterValueOptions.map((option) => (
                                <MenuItem value={option} key={option}>
                                    {option}
                                </MenuItem>
                            ))}
                            <MenuItem
                                disableRipple
                                onKeyDown={(e: React.KeyboardEvent<HTMLElement>): void => {
                                    e.stopPropagation();
                                }}
                                onClickCapture={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                }}
                            >
                                <TextField
                                    size="small"
                                    value={eventFilterCustomValueInput}
                                    type="number"
                                    autoComplete="off"
                                    onChange={(e) => {
                                        setEventFilterCustomValueInput(e.target.value);
                                    }}
                                    onKeyDown={(e: React.KeyboardEvent<HTMLElement>): void => {
                                        if (e.key === 'Enter' && eventFilterCustomValueInput) {
                                            e.preventDefault();
                                            const eventFilterCustomValue = Number(eventFilterCustomValueInput);
                                            if (!eventFilterValueOptions.includes(eventFilterCustomValue)) {
                                                setEventFilterValueOptions((eventFilterValueOptions) => [
                                                    ...eventFilterValueOptions,
                                                    eventFilterCustomValue,
                                                ]);
                                            }
                                            setEventFilterValue(eventFilterCustomValue);
                                            setFilterType('future');
                                            setEventFilterCustomValueInput('');
                                            setOpenEventFilterValueSelect(false);
                                        }
                                    }}
                                />
                            </MenuItem>
                        </Select>
                        <Select
                            value={eventFilterUnit}
                            onChange={(e) => {
                                setEventFilterUnit(e.target.value as EventFilterUnit);
                                setEventFilterValueOptions(
                                    eventFilterUnitToValueOptions[e.target.value as EventFilterUnit]
                                );
                            }}
                            size="small"
                            sx={{ marginLeft: '8px' }}
                        >
                            {eventFilterUnitOptions.map((option) => (
                                <MenuItem disableRipple value={option} key={option}>
                                    {eventFilterValue > 1 ? `${option}s` : option}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                    <PaginatedTable
                        columns={scheduleEventsTableColumns(jobLinkIdType) as unknown as MRT_ColumnDef<ScheduleEvent>[]}
                        rows={filteredEventsList}
                        optionOverrides={
                            {
                                enableFacetedValues: true,
                                renderDetailPanel: ({ row }) => {
                                    const eventBlob = row.original.eventBlob;
                                    return (
                                        eventBlob && (
                                            <Box
                                                sx={{
                                                    display: 'grid',
                                                    gridTemplateColumns: '1fr 1fr 1fr',
                                                    rowGap: '4px',
                                                    marginLeft: '16px',
                                                }}
                                            >
                                                {Object.keys(eventBlob)
                                                    .sort()
                                                    .map(
                                                        (key) =>
                                                            eventBlob[key] && (
                                                                <Typography key={key} variant="body2">
                                                                    <span style={{ fontWeight: 'bold' }}>{key}</span>:{' '}
                                                                    {eventBlob[key]}
                                                                </Typography>
                                                            )
                                                    )}
                                            </Box>
                                        )
                                    );
                                },
                                localization: {
                                    expandAll: 'Expand all event details',
                                    expand: 'Expand event details'
                                },
                            } as MRT_TableOptions<ScheduleEvent>
                        }
                    ></PaginatedTable>
                </div>
            ) : (
                <div className={classes.noEventsBox}>No Events</div>
            )}
        </div>
    );
};

export default ScheduleEventsTable;
