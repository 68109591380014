import React, { useState } from 'react';
import { Box, Button, FormControl, Grid, MenuItem, Select, SelectChangeEvent, TextField, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ScheduleEventSource } from '@webacq/wa-shared-definitions';

import { createDummyScheduleGroup, processScheduleGroupDetailsBeforeSaving } from '../utils';

import AddGroupMemberSection from '../components/ScheduleGroupPage/AddGroupMemberSection';
import FlashMessage from '../components/ScheduleGroupPage/FlashMessage';
import Footer from '../components/Footer';
import GroupMembersSection from '../components/ScheduleGroupPage/GroupMembersSection';
import Header from '../components/Header';
import LinkEventsInput from '../components/ScheduleGroupPage/LinkEventsInput';
import LinkedIdsSection from '../components/ScheduleGroupPage/LinkedIdsSection';
import ScheduleTemplateSection from '../components/ScheduleGroupPage/ScheduleTemplateSection';

import { ScheduleGroup } from '../types/schedule/scheduleGroup';

import wamService from '../services/wamService';

import { ScheduleGroupContextProvider, useScheduleGroupContext } from '../context/scheduleGroup';

const useStyles = makeStyles(() => {
    return {
        italic: {
            fontStyle: 'italic',
        },
        section: {
            marginTop: '32px',
            marginBottom: '32px',
            width: '98%',
        },
        sectionTitle: {
            fontSize: '2rem',
            fontWeight: 800,
        },
        sectionSubTitle: {
            fontSize: '1.35rem',
        },
        bbidLookupErrorMessage: {
            color: '#F44336',
            fontSize: '1rem',
            paddingLeft: '16px',
        },
        unknownBbidList: {
            marginTop: '4px',
            paddingLeft: '16px',
        },
        allEventsLinkContainer: {
            width: '100%',
            height: '64px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        allEventsLink: {
            fontSize: '1.25rem',
        },
    };
});

const Section = (props: { children: React.ReactFragment }): JSX.Element => {
    const classes = useStyles();

    return <div className={classes.section}>{props.children}</div>;
};

const NewScheduleGroup = (): JSX.Element => {
    const classes = useStyles();
    const scheduleGroupContext = useScheduleGroupContext();

    const [groupDataCopy, setGroupDataCopy] = useState<ScheduleGroup | null>(null);

    const { groupData, setGroupData, setFlashMessage, setIdLookupResult, isGroupValid, groupValidationErrors } =
        scheduleGroupContext;

    const disableSaveButton = JSON.stringify(groupDataCopy) === JSON.stringify(groupData);

    const saveButtonTooltips: string[] = [...groupValidationErrors];

    const handleSave = async () => {
        const saveScheduleGroup = async () => {
            const processedGroupDetails = processScheduleGroupDetailsBeforeSaving(groupData);

            const saveResult = await wamService.createNewScheduleGroup(processedGroupDetails);

            if (saveResult) {
                setFlashMessage({
                    type: 'success',
                    operation: 'Created',
                    groupName: saveResult.newGroupName,
                    groupID: saveResult.newGroupId,
                });
                setGroupDataCopy(structuredClone(groupData));
            } else {
                setFlashMessage({ type: 'error' });
            }
        };

        saveScheduleGroup();
    };

    return (
        <Box display="flex" flexDirection="column" height="100vh">
            <Header subtitle="New Schedule Group" />
            <FlashMessage />
            <Box flexGrow={1} overflow="auto" marginLeft="48px" marginY="20px">
                <h1>Create a New Schedule Group</h1>
                <Section>
                    <h2 className={classes.sectionTitle}>Group Name</h2>
                    <TextField
                        placeholder="Enter Group Name"
                        variant="standard"
                        size="medium"
                        required
                        fullWidth
                        inputProps={{
                            style: { fontSize: '1.25rem' },
                        }}
                        value={groupData.groupName}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setGroupData({ ...groupData, groupName: event.target.value });
                        }}
                        autoComplete="off"
                    />
                </Section>
                <Section>
                    <h2 className={classes.sectionTitle}>Schedule Event Type</h2>
                    <FormControl
                        required
                        sx={{
                            width: '50%',
                        }}
                    >
                        <Select
                            value={groupData.eventSource}
                            onChange={(event: SelectChangeEvent) => {
                                setGroupData({
                                    ...groupData,
                                    eventSource: event.target.value as ScheduleEventSource,
                                });
                            }}
                        >
                            {['evts', 'eco', 'day'].map((source) => (
                                <MenuItem value={source} key={source}>
                                    {source.toUpperCase()}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Section>
                <Section>
                    <h2 className={classes.sectionTitle}>Events</h2>
                    <LinkEventsInput />
                    {groupData.linkedEntities.length > 0 && <LinkedIdsSection viewOnly={false}/>}
                </Section>
                <Section>
                    <h2 className={classes.sectionTitle}>
                        Job Configs <span className={classes.sectionSubTitle}>({groupData.members.length} added)</span>
                    </h2>
                    <AddGroupMemberSection />
                    <GroupMembersSection viewOnly={false}/>
                </Section>
                <Section>
                    <h2 className={classes.sectionTitle}>Schedule Template</h2>
                    <ScheduleTemplateSection viewOnly={false} />
                </Section>
            </Box>
            <Grid container spacing={2} sx={{ paddingLeft: '32px', marginBottom: '16px' }}>
                <Grid item>
                    <Tooltip
                        title={
                            (saveButtonTooltips.length > 0 &&
                                saveButtonTooltips.map((tooltip) => <div key={tooltip}>{tooltip}</div>)) ||
                            (disableSaveButton && 'Make changes to save.')
                        }
                        placement="top-end"
                    >
                        <span>
                            <Button
                                variant="contained"
                                size="medium"
                                disableRipple
                                onClick={handleSave}
                                disabled={!isGroupValid || disableSaveButton}
                            >
                                Save
                            </Button>
                        </span>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Button
                        variant="outlined"
                        size="medium"
                        disableRipple
                        color="secondary"
                        onClick={() => {
                            setGroupData(createDummyScheduleGroup());
                            setIdLookupResult({ invalidIds: [], validIds: [], existingIds: [] });
                        }}
                    >
                        Clear
                    </Button>
                </Grid>
            </Grid>
            <Footer year={2025}></Footer>
        </Box>
    );
};

const NewScheduleGroupPage = (): JSX.Element => {
    return (
        <ScheduleGroupContextProvider>
            <NewScheduleGroup />
        </ScheduleGroupContextProvider>
    );
};

export default NewScheduleGroupPage;
