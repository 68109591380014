import React from 'react';
import { TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useScheduleGroupContext } from '../../context/scheduleGroup';
import LinkEventsByBBID from './LinkEventsByBBID';

const useStyles = makeStyles(() => {
    return {
        sectionSubTitle: {
            fontSize: '1.35rem',
        },
    };
});

const LinkEventsInput = (): JSX.Element => {
    const classes = useStyles();
    const scheduleGroupContext = useScheduleGroupContext();

    const { groupData } = scheduleGroupContext;

    switch (groupData.eventSource) {
        case 'evts':
            return (
                <>
                    <div className={classes.sectionSubTitle}>
                        Link this group with schedule events by entering company BBID(s)
                    </div>
                    <LinkEventsByBBID></LinkEventsByBBID>
                </>
            );
        case 'eco':
            // TODO
            return (
                <>
                    <div className={classes.sectionSubTitle} style={{ marginBottom: '12px' }}>
                        Link this group with events by entering Ticker(s)
                    </div>
                    <TextField
                        variant="outlined"
                        size="small"
                        label="Ticker lookup"
                        fullWidth
                        inputProps={{
                            style: { fontSize: '1.25rem' },
                        }}
                    ></TextField>
                </>
            );
        case 'day':
            // TODO
            return <>Link-Events Input for DAY</>;
    }
};

export default LinkEventsInput;
