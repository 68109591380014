import React from 'react';
import { IconButton, InputAdornment, Popover, TextField } from '@mui/material';
import { HelpOutlined as HelpOutlinedIcon, Search as SearchIcon } from '@mui/icons-material';
import Instructions from '../Instructions';

interface OmniBarProps {
    initialValue?: string;
    grabFocus?: boolean;
    embedded?: boolean;
    onEnter?: (searchterm: string) => void;
}

const OmniBar = (props: OmniBarProps): JSX.Element => {
    const [searchterm, setSearchterm] = React.useState(props.initialValue || '');
    const [helpAnchorEl, setHelpAnchorEl] = React.useState<HTMLElement | null>(null);
    const isHelpOpen = Boolean(helpAnchorEl);
    const inputRef = React.useRef<HTMLInputElement>();

    const handleKeypress = (event: KeyboardEvent) => {
        if (event.key === '/') {
            if (inputRef && document.activeElement !== inputRef.current) {
                event.preventDefault();
                inputRef.current?.focus();
            }
        }
    };

    React.useEffect(() => {
        document.addEventListener('keypress', handleKeypress);

        return () => {
            document.removeEventListener('keypress', handleKeypress);
        };
    }, []);

    React.useEffect(() => {
        setSearchterm(props.initialValue || '');
    }, [props.initialValue]);

    return (
        <TextField
            inputRef={inputRef}
            autoFocus={props.grabFocus}
            value={searchterm}
            placeholder="Search for..."
            variant="outlined"
            fullWidth
            size={props.embedded ? 'small' : undefined}
            style={props.embedded ? {} : { padding: '1em', width: '60%', alignSelf: 'center' }}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                ),
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                setHelpAnchorEl(event.target as HTMLElement);
                            }}
                            size="large"
                        >
                            <HelpOutlinedIcon />
                        </IconButton>
                        <Popover
                            anchorEl={helpAnchorEl}
                            open={isHelpOpen}
                            onClose={() => setHelpAnchorEl(null)}
                            disableRestoreFocus
                        >
                            <Instructions />
                        </Popover>
                    </InputAdornment>
                ),
            }}
            onChange={(event) => {
                setSearchterm(event.currentTarget.value);
            }}
            onKeyUp={(event) => {
                if (event.key === 'Enter' && props.onEnter) {
                    props.onEnter(searchterm);
                }
            }}
        />
    );
};

export default OmniBar;
