import React, { useEffect, useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    CircularProgress,
    IconButton,
    InputAdornment,
    TextField,
    Tooltip,
    Breakpoint,
} from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';

import { JobConfig } from '../../types/schedule/scheduleGroup';
import JobConfigSearchResultTable from './JobConfigSearchResultTable';
import { makeStyles } from '@mui/styles';
import { groupJobConfigsByIri, processJobConfigSearchResult, searchJobConfigs } from '../../utils';
import { useScheduleGroupContext } from '../../context/scheduleGroup';
import wamService from '../../services/wamService';

const useStyles = makeStyles(() => {
    return {
        searchResultTableHeading: {
            fontSize: '1.25rem',
            fontWeight: 700,
            marginBottom: '16px',
        },
        italic: {
            fontStyle: 'italic',
        },
        buttonName: {
            color: '#2B8EFF',
        },
        jobConfigSearchResultTableContainer: {
            marginTop: '16px',
        },
        noJobConfigsFoundMessage: {
            width: '100%',
            textAlign: 'center',
            paddingTop: '64px',
            paddingBottom: '64px',
            fontSize: '1.25rem',
        },
    };
});

const AddJobConfigSection = (): JSX.Element => {
    const classes = useStyles();
    const scheduleGroupContext = useScheduleGroupContext();

    const {
        groupData,
        setGroupData,
        iriToEntityName,
        jobConfigSearchInputValue,
        setJobConfigSearchInputValue,
        updateIriToEntityNameMap,
    } = scheduleGroupContext;

    const [searchBarInputValue, setSearchBarInputValue] = useState<string>('');

    const [jobConfigSearchDialogOpen, setJobConfigSearchDialogOpen] = useState<boolean>(false);
    const [isSearching, setIsSearching] = useState<boolean>(false);

    const [jobConfigSearchResultGroupedByIRI, seJobConfigSearchResultGroupedByIRI] = useState<{
        [iri: string]: JobConfig[];
    }>({});

    const [selectedJobConfigs, setSelectedJobConfigs] = useState<JobConfig[]>(groupData.members);

    useEffect(() => {
        if (jobConfigSearchInputValue) {
            setSearchBarInputValue(jobConfigSearchInputValue);
            handleSearch(jobConfigSearchInputValue);
        }
    }, [jobConfigSearchInputValue]);

    const handleSearch = (searchQuery: string) => {
        setJobConfigSearchDialogOpen(true);
        setSelectedJobConfigs([]);

        const runSearch = async () => {
            setIsSearching(true);
            const searchRes = await searchJobConfigs(searchQuery);

            const jobConfigs = processJobConfigSearchResult(searchRes);
            const jobConfigsGroupedByIri = groupJobConfigsByIri(jobConfigs);
            seJobConfigSearchResultGroupedByIRI(jobConfigsGroupedByIri);

            const tempIriToEntityName: { [iri: string]: string } = {};
            const entities = await wamService.lookupEntitiesByIRIs(Object.keys(jobConfigsGroupedByIri));
            if (entities) {
                entities.forEach((entity) => {
                    if ('entityInstanceIRI' in entity && entity.entityInstanceIRI && entity.label) {
                        tempIriToEntityName[entity.entityInstanceIRI] = entity.label;
                    }
                });
                updateIriToEntityNameMap(tempIriToEntityName);
            }

            setIsSearching(false);
        };

        runSearch();
    };

    const closeJobConfigSearchDialog = () => {
        setJobConfigSearchInputValue('');
        setJobConfigSearchDialogOpen(false);
    };

    const handleSearchResultSelection = (updatedSelects: JobConfig[]) => {
        setSelectedJobConfigs((selectedJobConfigs) => {
            const newSelects = updatedSelects.filter(
                (select) => !selectedJobConfigs.find((selected) => selected.jobConfigId === select.jobConfigId)
            );

            return [...selectedJobConfigs, ...newSelects];
        });
    };

    const handleAddConfirmation = () => {
        setGroupData((groupData) => {
            const newMembers = [...groupData.members, ...selectedJobConfigs];
            const uniqueMembers = newMembers.filter(
                (member, index, self) => index === self.findIndex((m) => m.jobConfigId === member.jobConfigId)
            );

            return {
                ...groupData,
                members: uniqueMembers,
            };
        });

        setJobConfigSearchInputValue('');
        closeJobConfigSearchDialog();
    };

    return (
        <>
            <TextField
                fullWidth
                variant="outlined"
                size="medium"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start" disablePointerEvents={!searchBarInputValue}>
                            <Tooltip title="Search for Job Configs">
                                <span>
                                    <IconButton
                                        onClick={() => handleSearch(searchBarInputValue)}
                                        disabled={!searchBarInputValue}
                                    >
                                        <SearchIcon />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        </InputAdornment>
                    ),
                }}
                placeholder="Search for job configs by IRI or other fields"
                autoComplete="off"
                value={searchBarInputValue}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setSearchBarInputValue(event.target.value);
                }}
                onKeyDown={(e: React.KeyboardEvent<HTMLElement>): void => {
                    if (e.key === 'Enter' && searchBarInputValue) {
                        e.preventDefault();
                        handleSearch(searchBarInputValue);
                    }
                }}
            />
            <Dialog maxWidth={'95vw' as Breakpoint} fullWidth open={jobConfigSearchDialogOpen}>
                <DialogTitle>
                    Job Configs Found for <span style={{ fontStyle: 'italic' }}>{searchBarInputValue}</span>
                </DialogTitle>
                <DialogContent dividers>
                    {isSearching ? (
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <CircularProgress />
                        </div>
                    ) : Object.keys(jobConfigSearchResultGroupedByIRI).length > 0 ? (
                        Object.keys(jobConfigSearchResultGroupedByIRI).map((iri: string) => (
                            <div className={classes.jobConfigSearchResultTableContainer} key={iri}>
                                <div className={classes.searchResultTableHeading}>
                                    {iri && (
                                        <>
                                            <div>
                                                <span className={classes.italic}>{iriToEntityName[iri]}</span>, IRI:{' '}
                                                <span className={classes.italic}>{iri}</span>
                                            </div>
                                            <JobConfigSearchResultTable
                                                jobConfigSearchResult={jobConfigSearchResultGroupedByIRI[iri]}
                                                selectedJobConfigs={groupData.members}
                                                handleSearchResultSelection={handleSearchResultSelection}
                                            />
                                        </>
                                    )}
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className={classes.noJobConfigsFoundMessage}>No Job Configs Found</div>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={closeJobConfigSearchDialog}>
                        Cancel
                    </Button>
                    <Button onClick={handleAddConfirmation}>Add Selected to Group</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default AddJobConfigSection;
