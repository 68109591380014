import React from 'react';
import { Link as LinkRouter, useLocation } from 'react-router-dom';
import { useQueryParam, StringParam, ArrayParam, withDefault } from 'use-query-params';
import { Alert, Box, Grid, LinearProgress, Link, Theme, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { TableChart as SummaryIcon, PieChart as MigrationIcon } from '@mui/icons-material';
import { v4 as uuidv4 } from 'uuid';
import { SearchDoc, SearchDocRaw } from '@webacq/wa-shared-definitions';
import { HighlightRecords } from '@webacq/wa-shared-servicewrappers';
import { CrwlIcon } from '@webacq/wam-ui-components';
import SearchResultList, {
    SupportedDocTypes as SupportedSearchResultDocTypes,
} from '../components/search/SearchResultList';
import searchService from '../services/searchService';
import { useAuth } from '../context/auth';
import { DocTypeName, DocTypes, QueryStrings, SOLRFieldMapping } from '../shared/constants';
import Content from '../components/Content';
import FacetSelector, { Facet } from '../components/search/FacetSelector';
import { formatLargeNumber, getSearchRoute, pluralSuffix, wrapApiCall } from '../utils';
import OmniBar from '../components/search/OmniBar';
import DocTypeIcon from '../components/DocTypeIcon';

const useStyles = makeStyles((theme: Theme) => ({
    box: {
        margin: theme.spacing(1),
    },
    facetIcon: {
        filter: 'brightness(70%)',
    },
    buttonLink: {
        paddingLeft: theme.spacing(8),
        paddingRight: theme.spacing(8),
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    linksBox: {
        marginTop: theme.spacing(8),
    },
}));

const getQueryLinks = () => {
    const getSkipFacets = (docTypesToKeep: DocTypes[]) =>
        Object.values(DocTypes).filter((docType) => !docTypesToKeep.includes(docType));

    return [
        {
            label: "All jobs that are running in 'Live' mode",
            route: getSearchRoute('jobMode=live', getSkipFacets([DocTypes.JOB])),
        },
        {
            label: 'All job configurations that were last edited by a specific user',
            route: getSearchRoute('jc_up_by_name_t:"John Doe"', getSkipFacets([DocTypes.JOB_CONFIG])),
        },
        {
            label: "All jobs that are running in 'Test' mode",
            route: getSearchRoute('jobMode=test', getSkipFacets([DocTypes.JOB])),
        },
        {
            label: 'All jobs with deliveries in the last 3 days',
            route: getSearchRoute('bbdsPublish=[NOW-3DAY/DAY TO NOW]', getSkipFacets([DocTypes.JOB_DLV])),
        },
        {
            label: "All job configurations that are using the 'Profiles' template",
            route: getSearchRoute('jobConfigTemplateName=Profiles', getSkipFacets([DocTypes.JOB_CONFIG])),
        },
        {
            label: 'All jobs with errors occurring in the last 3 days',
            route: getSearchRoute(
                'job_start_time_dt:[NOW-3DAY/DAY TO NOW] OR start_time_dt:[NOW-3DAY/DAY TO NOW]',
                getSkipFacets([DocTypes.JOB_ERR, DocTypes.SYS_ERROR])
            ),
        },
        {
            label: "All job configurations that are using the 'ArticleX' template",
            route: getSearchRoute('jobConfigTemplateName=ArticleX', getSkipFacets([DocTypes.JOB_CONFIG])),
        },
        {
            label: 'All job configurations with a specific Starting Url',
            route: getSearchRoute('job_start_url_ss:*www.bloomberg.com*', getSkipFacets([DocTypes.JOB_CONFIG])),
        },
        {
            label: "All job configurations that are using the 'Private Equity' template",
            route: getSearchRoute('jobConfigTemplateName="Private Equity"', getSkipFacets([DocTypes.JOB_CONFIG])),
        },
    ];
};

const buttonLinks = [
    {
        label: 'Summary',
        url: '/Summary',
        icon: <SummaryIcon />,
    },
    {
        label: 'BBOT to Crawl Migration Status',
        url: 'https://grafana.prod.bloomberg.com/d/d6599445-6a37-4aee-8f0b-c9a5e2caf498/bbot-to-crawl-migration-high-level?orgId=1',
        icon: <MigrationIcon />,
        newWindow: true,
    },
];

interface SearchresultState {
    docs?: SupportedSearchResultDocTypes[];
    highlightData?: HighlightRecords;
    cursorMarks: Record<string, string>;
}

const Home = (): JSX.Element => {
    const authContext = useAuth();
    const classes = useStyles();
    const location = useLocation();

    const [isLoading, setIsLoading] = React.useState(false);
    const [errormsg, setErrormsg] = React.useState('');

    const [searchterm, setSearchterm] = useQueryParam(QueryStrings.SEARCH, withDefault(StringParam, ''));
    const [uniqueSearchId, setUniqueSearchId] = useQueryParam('sid', withDefault(StringParam, ''));
    const [deselectedFacets, setDeselectedFacets] = useQueryParam('skipfacets', withDefault(ArrayParam, []));

    const [searchresultState, setSearchresultState] = React.useState<SearchresultState>({ cursorMarks: {} });

    const [facetInfo, setFacetInfo] = React.useState<Facet[]>(
        Object.values(DocTypes).map((docType) => ({
            key: docType,
            label: DocTypeName(docType),
        }))
    );

    const cleanUpDeselectedFacets = () => {
        const cleanedUpFacets: string[] = [];
        if (deselectedFacets) {
            deselectedFacets.forEach((facet) => {
                if (facet) {
                    cleanedUpFacets.push(facet);
                }
            });
        }

        return cleanedUpFacets;
    };

    const loadNextData = async (clearSearchstate: boolean) => {
        if (searchterm) {
            const maxRecordsPerRequest = 20;

            const mapSearchterm = (docType: string, searchterm: string): string => {
                switch (docType) {
                    case DocTypes.JOB:
                        if (searchterm) {
                            return `(${searchterm}) AND -job_status_s:deleted`;
                        }
                        break;
                    case DocTypes.JOB_CONFIG:
                        if (searchterm) {
                            return `(${searchterm}) AND is_routed_b:true`;
                        }
                        break;
                }
                return searchterm;
            };

            const mapSearchSorting = (docType: string): string | undefined => {
                switch (docType) {
                    case DocTypes.JOB_CONFIG:
                        return 'jc_up_at_dt+desc,id+desc';
                    case DocTypes.JOB:
                        return 'job_up_at_dt+desc,id+desc';
                    case DocTypes.JOB_DLV:
                        return 'job_acq_time_dt+desc,id+desc';
                    case DocTypes.ALERT_NOTIF:
                        return 'alert_notif_aq_end_dt+desc,id+desc';
                    case DocTypes.JOB_ERR:
                        return 'job_start_time_dt+desc,id+desc';
                    case DocTypes.SYS_ERROR:
                        return 'start_time_dt+desc,id+desc';
                    case DocTypes.POST_PROCESSING:
                        // this will likely need to be tweaked once we are adidng more PP agents
                        return 'story_acq_time_dt+desc,id+desc';
                }
                return undefined;
            };

            setErrormsg('');
            setIsLoading(true);

            const solrSearchterm = Object.keys(SOLRFieldMapping).reduce((tempSearchterm, key) => {
                const value = SOLRFieldMapping[key];
                return ` ${tempSearchterm}`
                    .replaceAll(new RegExp(`([(\\s])(${key}\\s*=\\s*)`, 'ig'), `$1${value}:`)
                    .trim();
            }, searchterm);

            console.log(`translated searchterm from '${searchterm}' to '${solrSearchterm}'`);

            wrapApiCall(
                authContext,
                () => {
                    const requests = facetInfo.map((facet) =>
                        !cleanUpDeselectedFacets().includes(facet.key)
                            ? searchService.search<SearchDocRaw, SearchDoc>(
                                  mapSearchterm(facet.key, solrSearchterm),
                                  facet.key,
                                  maxRecordsPerRequest,
                                  clearSearchstate ? '*' : searchresultState.cursorMarks[facet.key] || '*',
                                  mapSearchSorting(facet.key)
                              )
                            : clearSearchstate // we only need to make a facet query for initial searches
                            ? searchService.search<SearchDocRaw, SearchDoc>(
                                  mapSearchterm(facet.key, solrSearchterm),
                                  facet.key,
                                  0,
                                  undefined,
                                  undefined,
                                  'fc'
                              )
                            : undefined
                    );

                    return Promise.all(requests).then((results) => {
                        const newDocs = clearSearchstate ? [] : [...(searchresultState.docs || [])];
                        let newHighlightData = clearSearchstate ? {} : { ...searchresultState.highlightData };
                        const newCursorMarks = clearSearchstate ? {} : { ...searchresultState.cursorMarks };

                        const newFacetInfo = clearSearchstate ? [...facetInfo] : [];
                        results.forEach((result, idx) => {
                            if (result) {
                                const facetKey = facetInfo[idx].key;

                                const data = result.data;

                                if (data && !cleanUpDeselectedFacets().includes(facetKey)) {
                                    newDocs.push(...data.docs);
                                    newHighlightData = { ...newHighlightData, ...data.highlighting };
                                    newCursorMarks[facetKey] = data.nextCursorMark;
                                }

                                // we only need to update facets if it's a new initial search
                                if (clearSearchstate) {
                                    const facet = newFacetInfo.find((facet) => facet.key === facetKey);
                                    if (data && facet) {
                                        const facetFields = (data.facetCounts['facet_fields'] || {}) as Record<
                                            string,
                                            Record<string, number>
                                        >;
                                        const facetCounts = facetFields['type_s'] || {};
                                        facet.label = (
                                            <>
                                                <span className={classes.facetIcon}>
                                                    <DocTypeIcon type={facet.key as DocTypes} />
                                                </span>
                                                &nbsp;
                                                {DocTypeName(facet.key)}
                                                &nbsp;
                                                <Tooltip
                                                    title={`${(
                                                        facetCounts[facet.key] || 0
                                                    ).toLocaleString()} ${DocTypeName(facet.key)} record${pluralSuffix(
                                                        facetCounts[facet.key]
                                                    )}`}
                                                >
                                                    <Typography variant="body2" color="textSecondary" component="span">
                                                        {`(${formatLargeNumber(facetCounts[facet.key] || 0)})`}
                                                    </Typography>
                                                </Tooltip>
                                            </>
                                        );
                                    }
                                }
                            }
                        });

                        // we only need to update facet counts it it's a new initial search
                        if (clearSearchstate) {
                            setFacetInfo(newFacetInfo);
                        }

                        setSearchresultState({
                            docs: newDocs,
                            highlightData: newHighlightData,
                            cursorMarks: newCursorMarks,
                        });
                    });
                },
                (e: Error) => setErrormsg(e.message),
                () => setIsLoading(false)
            );
        } else {
            setSearchresultState({ cursorMarks: {} });
        }
    };

    React.useEffect(() => {
        loadNextData(true);
    }, [location.pathname, location.search, uniqueSearchId, authContext]);

    const getContent = (): JSX.Element => {
        return (
            <>
                {errormsg && (
                    <Alert
                        severity="error"
                        onClose={() => {
                            setErrormsg('');
                        }}
                    >
                        {errormsg}
                    </Alert>
                )}

                {isLoading && <LinearProgress />}

                <Box display="flex" flexDirection="column" height="100%">
                    {!searchterm && (
                        <Box display="flex" height="60%" justifyContent="center" alignItems="center">
                            <Typography style={{ fontSize: 72 }}>
                                <CrwlIcon
                                    fontSize="inherit"
                                    style={{ verticalAlign: 'text-top', marginRight: '0.3em' }}
                                />
                                CRWL HUB
                            </Typography>
                        </Box>
                    )}

                    <OmniBar
                        grabFocus={true}
                        initialValue={searchterm}
                        onEnter={(newSearchterm) => {
                            setSearchresultState({ cursorMarks: {} });
                            setUniqueSearchId(uuidv4(), 'pushIn');
                            setSearchterm(newSearchterm); // will trigger new search
                        }}
                    />

                    {!searchterm && (
                        <>
                            <Box display="flex" width="50%" alignSelf="center" justifyContent="center" flexWrap="wrap">
                                {buttonLinks.map((elem, idx) => (
                                    <Link
                                        key={idx}
                                        color="textSecondary"
                                        className={classes.buttonLink}
                                        variant="body1"
                                        target={elem.newWindow ? '_blank' : ''}
                                        href={elem.url}
                                    >
                                        <span style={{ verticalAlign: 'middle' }}>{elem.icon || <></>}</span>
                                        {/* <img src={elem.image} style={{ verticalAlign: 'middle' }} /> */}
                                        &nbsp;&nbsp;
                                        {elem.label}
                                    </Link>
                                ))}
                            </Box>

                            <Box className={classes.linksBox} width="50%" alignSelf="center" justifyContent="center">
                                <Grid container spacing={2} justifyContent="left">
                                    {getQueryLinks().map((elem, idx) => (
                                        <Grid item xs={6} key={idx}>
                                            <LinkRouter key={idx} to={elem.route}>
                                                {elem.label}
                                            </LinkRouter>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Box>
                        </>
                    )}

                    <Box flexGrow={1} overflow="hidden" height="100%">
                        <Box display="flex" flexDirection="row" height="99%">
                            <Box className={classes.box}>
                                {searchterm && (
                                    <FacetSelector
                                        title="Only show the following records"
                                        facets={facetInfo}
                                        deselectedFacets={cleanUpDeselectedFacets()}
                                        onFacetSelectionChanged={(_, selection) => {
                                            //setTopMostResultIndex(null);
                                            setSearchresultState({ cursorMarks: {} });
                                            setDeselectedFacets(
                                                facetInfo
                                                    .filter((fi) => selection[fi.key] === false)
                                                    .map((fi) => fi.key),
                                                'pushIn'
                                            ); // will trigger new search
                                        }}
                                    />
                                )}
                            </Box>
                            <Box flexGrow={1} overflow="auto" height="100%" className={classes.box} flexDirection="row">
                                {searchterm && (
                                    <SearchResultList
                                        docs={searchresultState.docs}
                                        highlightData={searchresultState.highlightData}
                                        onEndReached={() => {
                                            loadNextData(false);
                                        }}
                                        //onVisibleRangeChanged={(range) => setTopMostResultIndex(range.startIndex)}
                                        //topMostItemIndex={topMostResultIndex}
                                    />
                                )}
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </>
        );
    };

    const getQuicklinkTail = (): string => {
        const tailTokens = [`/S ${encodeURIComponent(searchterm)}`];

        deselectedFacets.forEach((facet) => {
            if (facet) {
                tailTokens.push(` /SF ${encodeURIComponent(facet)}`);
            }
        });

        return tailTokens.join(' ');
    };

    return (
        <Content quickLinkTail={getQuicklinkTail()} hideSearchBoxInHeader>
            {getContent()}
        </Content>
    );
};

export default Home;
