import React from 'react';
import { CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => {
    return {
        loadingIndicatorContainer: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            marginTop: '16px',
            marginBottom: '16px',
        },
    };
});

const LoadingIndicator = (): JSX.Element => {
    const classes = useStyles();

    return (
        <div className={classes.loadingIndicatorContainer}>
            <CircularProgress />
        </div>
    );
};

export default LoadingIndicator;
