import {
    EventScheduleTemplateWindowStep as EventScheduleTemplateWindowStepShardDef,
    EventScheduleTemplateIntervalOnlyStep as EventScheduleTemplateIntervalOnlyStepSharedDef,
} from '@webacq/wa-shared-definitions';

export enum ScheduleTemplateOffsetTimeUnit {
    HOUR = 'Hour',
    MINUTE = 'Minute',
    SECOND = 'Second',
    MILLISECOND = 'Millisecond',
}

export enum ScheduleTemplateIntervalTimeUnit {
    SECOND = 'Second',
    MILLISECOND = 'Millisecond',
}

export type ScheduleEventType = 'datetime' | 'datetimeRange'
export type DatetimeEventScheduleTemplatePhaseType = 'beforeRelease' | 'afterRelease'
export type DatetimeRangeEventScheduleTemplatePhaseType = 'beforeRelease' | 'duringRelease' | 'afterRelease'

export interface EventScheduleTemplateWindowStep extends EventScheduleTemplateWindowStepShardDef {
    startTimeOffsetValue: number;
    startTimeOffsetUnit: ScheduleTemplateOffsetTimeUnit;
    endTimeOffsetValue: number;
    endTimeOffsetUnit: ScheduleTemplateOffsetTimeUnit;
    intervalValue: number;
    intervalUnit: ScheduleTemplateIntervalTimeUnit;
}

export interface EventScheduleTemplateIntervalOnlyStep extends EventScheduleTemplateIntervalOnlyStepSharedDef {
    intervalValue: number;
    intervalUnit: ScheduleTemplateIntervalTimeUnit;
    allowOverlappedRun: boolean;
}
export interface EventScheduleTemplate {
    datetime: {
        beforeRelease: {
            intervals: EventScheduleTemplateWindowStep[];
        };
        afterRelease: {
            intervals: EventScheduleTemplateWindowStep[];
        };
    };
    datetimeRange: {
        beforeRelease: {
            intervals: EventScheduleTemplateWindowStep[];
        };
        duringRelease: EventScheduleTemplateIntervalOnlyStep;
        afterRelease: {
            intervals: EventScheduleTemplateWindowStep[];
        };
    };
}
