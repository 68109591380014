import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Button, FormControl, Grid, MenuItem, Select, TextField, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
    EventScheduleTemplateWindowStep as EventScheduleTemplateWindowStepSharedDef,
    EventScheduleTemplate as EventScheduleTemplateSharedDef,
} from '@webacq/wa-shared-definitions';

import {
    createDummyScheduleTemplate,
    deepCompareGroupData,
    formatTimestamp,
    parseScheduleTemplateTimeValueInMilliseconds,
    parseScheduleTemplateTimeValueInSeconds,
    processEntityLookupResult,
} from '../utils';

import FlashMessage from '../components/ScheduleGroupPage/FlashMessage';
import Footer from '../components/Footer';
import GroupMembersSection from '../components/ScheduleGroupPage/GroupMembersSection';
import Header from '../components/Header';
import LinkedIdsSection from '../components/ScheduleGroupPage/LinkedIdsSection';
import LoadingIndicator from '../components/ScheduleGroupPage/LoadingIndicator';
import ScheduleTemplateSection from '../components/ScheduleGroupPage/ScheduleTemplateSection';

import wamService from '../services/wamService';

import { Entity } from '../types/schedule/jobLinkID';
import {
    DatetimeEventScheduleTemplatePhaseType,
    EventScheduleTemplate as EventScheduleTemplateViewModel,
    ScheduleEventType,
} from '../types/schedule/scheduleTemplate';
import { JobConfig, ScheduleGroup } from '../types/schedule/scheduleGroup';

import { ScheduleGroupContextProvider, useScheduleGroupContext } from '../context/scheduleGroup';

const useStyles = makeStyles(() => {
    return {
        italic: {
            fontStyle: 'italic',
        },
        section: {
            marginTop: '32px',
            marginBottom: '32px',
            width: '98%',
        },
        sectionTitle: {
            fontSize: '2rem',
            fontWeight: 800,
        },
        sectionSubTitle: {
            fontSize: '1.35rem',
        },
        bbidLookupErrorMessage: {
            color: '#F44336',
            fontSize: '1rem',
            paddingLeft: '16px',
        },
        unknownBbidList: {
            marginTop: '4px',
            paddingLeft: '16px',
        },
        allEventsLinkContainer: {
            width: '100%',
            height: '64px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        allEventsLink: {
            fontSize: '1.25rem',
        },
        createdAndUpdatedInfoContainer: {
            marginTop: '32px',
            fontSize: '0.75rem',
            color: '#FFA028',
        },
    };
});

const processFetchedScheduleTemplate = (
    fetchedTemplate: EventScheduleTemplateSharedDef
): EventScheduleTemplateViewModel => {
    const processedTemplate = createDummyScheduleTemplate();

    const scheduleTypes: ScheduleEventType[] = ['datetime', 'datetimeRange'];
    const scheduleTemplateCommonPhases: DatetimeEventScheduleTemplatePhaseType[] = ['beforeRelease', 'afterRelease'];

    for (const scheduleType of scheduleTypes) {
        for (const phaseType of scheduleTemplateCommonPhases) {
            const intervals = fetchedTemplate[scheduleType][phaseType].intervals.map(
                (step: EventScheduleTemplateWindowStepSharedDef) => {
                    const parsedStartTime = parseScheduleTemplateTimeValueInSeconds(step.fromSeconds);
                    const parsedEndTime = parseScheduleTemplateTimeValueInSeconds(step.toSeconds);
                    const parsedInterval = parseScheduleTemplateTimeValueInMilliseconds(step.intervalMS);
                    return {
                        allowOverlappedRun: step.allowOverlappedRun,
                        fromSeconds: step.fromSeconds,
                        startTimeOffsetValue: parsedStartTime.value,
                        startTimeOffsetUnit: parsedStartTime.unit,
                        toSeconds: step.toSeconds,
                        endTimeOffsetValue: parsedEndTime.value,
                        endTimeOffsetUnit: parsedEndTime.unit,
                        intervalMS: step.intervalMS,
                        intervalValue: parsedInterval.value,
                        intervalUnit: parsedInterval.unit,
                    };
                }
            );
            processedTemplate[scheduleType][phaseType].intervals = intervals;
        }
    }

    const parsedDuringReleaseInterval = parseScheduleTemplateTimeValueInMilliseconds(
        fetchedTemplate.datetimeRange.duringRelease.intervalMS
    );
    processedTemplate.datetimeRange.duringRelease = {
        intervalMS: fetchedTemplate.datetimeRange.duringRelease.intervalMS,
        intervalValue: parsedDuringReleaseInterval.value,
        intervalUnit: parsedDuringReleaseInterval.unit,
        allowOverlappedRun: fetchedTemplate.datetimeRange.duringRelease.allowOverlappedRun,
    };

    return processedTemplate;
};

const Section = (props: { children: React.ReactFragment }): JSX.Element => {
    const classes = useStyles();

    return <div className={classes.section}>{props.children}</div>;
};

const ViewScheduleGroup = (): JSX.Element => {
    const classes = useStyles();
    const scheduleGroupContext = useScheduleGroupContext();

    const { groupData, setGroupData, groupValidationErrors } = scheduleGroupContext;

    const [isLoadingGroupDetails, setIsLoadingGroupDetails] = useState<boolean>(true);
    const [loadingMessage, setLoadingMessage] = useState<string | null>(null);

    const [groupDataCopy, setGroupDataCopy] = useState<ScheduleGroup | null>(null);

    const { scheduleGroupId } = useParams<{ scheduleGroupId: string }>();

    const disableUpdateButton = groupDataCopy ? deepCompareGroupData(groupDataCopy, groupData) : true;

    const updatedButtonTooltips: string[] = [...groupValidationErrors];

    const fetchScheduleGroup = async () => {
        setIsLoadingGroupDetails(true);

        const fetched = await wamService.getScheduleGroup(scheduleGroupId);

        if (!fetched) {
            return;
        }

        // get createdBy and updatedBy users' names
        const createdByUserInfo = await wamService.getUserName(fetched.scheduleGroup.createdBy);
        const createdBy = `${createdByUserInfo.firstName} ${createdByUserInfo.lastName}`;

        let updatedBy;
        const updatedByUserId = fetched.scheduleGroup.updatedBy;
        if (updatedByUserId) {
            const updatedByUserInfo = await wamService.getUserName(updatedByUserId);
            updatedBy = `${updatedByUserInfo.firstName} ${updatedByUserInfo.lastName}`;
        }

        // mapping from IRI to entity names, so that we can display names next to IRIs, will be populated when:
        // 1. looking up information about entities (companies) linked to this group
        // 2. looking up IRI info when processing each group member (job config)
        const tempIriToEntityName: { [iri: string]: string } = {};

        const linkedEntities: Entity[] = [];
        if (fetched.scheduleGroup.eventSource === 'evts') {
            const bbids = fetched.scheduleGroup.eventLinkAttr.bbid;
            if (Array.isArray(bbids)) {
                const bulkSize = 2000;
                for (let i = 0; i < bbids.length; i += bulkSize) {
                    setLoadingMessage(`Processing linked BBIDs, ${i} - ${i + bulkSize} out of ${bbids.length}`);
                    const bulk = bbids.slice(i, i + bulkSize);
                    const entities = await wamService.lookupEntitiesByBBIDs(bulk);
                    if (entities) {
                        entities.forEach((entity) => {
                            if ('entityInstanceIRI' in entity) {
                                const processedEntity = processEntityLookupResult(entity);
                                linkedEntities.push(processedEntity);

                                if (processedEntity.entityInstanceIRI && entity.label) {
                                    tempIriToEntityName[processedEntity.entityInstanceIRI] = entity.label;
                                }
                            }
                        });
                    }
                }
            }
        }

        const members: JobConfig[] = fetched.scheduleGroupJobConfigIds.map((jobConfigId) => ({
            jobConfigId,
        }));

        const template = processFetchedScheduleTemplate(fetched.scheduleGroup.template);

        const fetchedScheduleGroup: ScheduleGroup = {
            ...fetched.scheduleGroup,
            template,
            linkedEntities,
            members,
            createdBy,
            updatedBy,
        };

        setGroupData(fetchedScheduleGroup);
        setGroupDataCopy(structuredClone(fetchedScheduleGroup));
        setLoadingMessage(null);
        setIsLoadingGroupDetails(false);
    };

    useEffect(() => {
        fetchScheduleGroup();
    }, []);

    if (isLoadingGroupDetails) {
        return (
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100vh">
                <LoadingIndicator />
                <div style={{ fontSize: '1.5rem', marginTop: '20px' }}>{loadingMessage}</div>
            </Box>
        );
    }

    return (
        <Box display="flex" flexDirection="column" height="100vh">
            <Header subtitle="Schedule Group" />
            <FlashMessage />
            <Box flexGrow={1} overflow="auto" marginLeft="48px" marginY="20px">
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <h1>
                            Schedule Group: <span className={classes.italic}>{groupDataCopy?.groupName}</span>
                        </h1>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <div className={classes.createdAndUpdatedInfoContainer}>
                            <h2>
                                Created on{' '}
                                <span className={classes.italic}>
                                    {formatTimestamp(new Date(groupData.created || ''))}
                                </span>{' '}
                                by <span className={classes.italic}> {groupData.createdBy}</span>
                            </h2>
                            <h2>
                                Updated on{' '}
                                <span className={classes.italic}>
                                    {formatTimestamp(new Date(groupData.updated || ''))}
                                </span>{' '}
                                by <span className={classes.italic}> {groupData.updatedBy}</span>
                            </h2>
                        </div>
                    </Grid>
                </Grid>
                <Section>
                    <h2 className={classes.sectionTitle}>Group Name</h2>
                    <TextField
                        disabled
                        placeholder="Enter Group Name"
                        variant="standard"
                        size="medium"
                        required
                        fullWidth
                        inputProps={{
                            style: { fontSize: '1.25rem' },
                        }}
                        value={groupData.groupName}
                        autoComplete="off"
                    />
                </Section>
                <Section>
                    <h2 className={classes.sectionTitle}>Schedule Event Type</h2>
                    <FormControl
                        required
                        sx={{
                            width: '50%',
                        }}
                    >
                        <Select disabled value={groupData.eventSource}>
                            {['evts', 'eco', 'day'].map((source) => (
                                <MenuItem value={source} key={source}>
                                    {source.toUpperCase()}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Section>
                <Section>
                    <h2 className={classes.sectionTitle}>Events</h2>
                    {groupData.linkedEntities.length > 0 && <LinkedIdsSection viewOnly />}
                </Section>
                <Section>
                    <h2 className={classes.sectionTitle}>
                        Job Configs <span className={classes.sectionSubTitle}>({groupData.members.length} added)</span>
                    </h2>
                    <GroupMembersSection viewOnly />
                </Section>
                <Section>
                    <h2 className={classes.sectionTitle}>Schedule Template</h2>
                    <ScheduleTemplateSection viewOnly />
                </Section>
            </Box>
            <Grid container spacing={2} sx={{ paddingLeft: '32px', marginBottom: '16px' }}>
                <Grid item>
                    <Tooltip
                        title={
                            (updatedButtonTooltips.length > 0 &&
                                updatedButtonTooltips.map((tooltip) => <div key={tooltip}>{tooltip}</div>)) ||
                            (disableUpdateButton && 'Make changes to update.')
                        }
                        placement="top-end"
                    >
                        <span>
                            <Button
                                variant="contained"
                                size="medium"
                                disableRipple
                                component="a"
                                href={`/edit-schedule-group/${scheduleGroupId}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Edit
                            </Button>
                        </span>
                    </Tooltip>
                </Grid>
            </Grid>
            <Footer year={2025}></Footer>
        </Box>
    );
};

const ViewScheduleGroupPage = (): JSX.Element => {
    return (
        <ScheduleGroupContextProvider>
            <ViewScheduleGroup />
        </ScheduleGroupContextProvider>
    );
};

export default ViewScheduleGroupPage;
