import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Chip,
    CircularProgress,
    Grid,
    IconButton,
    InputAdornment,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';

import Header from '../components/Header';
import wamService from '../services/wamService';
import { formatTimestamp } from '../utils';

/* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
const sortScheduleGroups = (groups: any[]) => {
    return groups.sort((g1, g2) => {
        const updatedG1 = new Date(g1.updated || '').getTime();
        const updatedG2 = new Date(g2.updated || '').getTime();

        return updatedG2 - updatedG1;
    });
};

const ScheduleGroups = (): JSX.Element => {
    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    const [scheduleGroups, setScheduleGroups] = useState<any[]>([]);
    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    const [filteredScheduleGroups, setFilteredScheduleGroups] = useState<any[]>([]);

    const [searchBarInputValue, setSearchBarInputValue] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const userIdToUserName: { [userId: string]: string } = {};
    const eventLinkIdToCompanyName: { [id: string]: string } = {};
    const companyNameToEventLinkId: { [id: string]: string } = {};

    const [auxData, setAuxData] = useState<{
        /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
        userIdToUserName: any;
        /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
        eventLinkIdToCompanyName: any;
        /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
        companyNameToEventLinkId: any;
    }>({
        userIdToUserName: {},
        eventLinkIdToCompanyName: {},
        companyNameToEventLinkId: {},
    });

    useEffect(() => {
        const loadAllScheduleGroups = async () => {
            setIsLoading(true);
            const allScheduleGroups = await wamService.getAllScheduleGroups();
            await processGroups(allScheduleGroups);
            setAuxData({ userIdToUserName, eventLinkIdToCompanyName, companyNameToEventLinkId });
            setScheduleGroups(sortScheduleGroups(allScheduleGroups));
            setFilteredScheduleGroups(sortScheduleGroups(allScheduleGroups));
            setIsLoading(false);
        };

        loadAllScheduleGroups();

        /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
        const processGroups = async (scheduleGroupsToProcess: any[]) => {
            for (const scheduleGroup of scheduleGroupsToProcess) {
                const getUserNames = async () => {
                    const createdByUserId = scheduleGroup.createdBy;
                    if (createdByUserId && !userIdToUserName[createdByUserId]) {
                        // load username
                        const userName = await wamService.getUserName(createdByUserId);
                        userIdToUserName[createdByUserId] = `${userName.firstName} ${userName.lastName}`;
                    }
                    const updatedByUserId = scheduleGroup.updatedBy;
                    if (updatedByUserId && !userIdToUserName[updatedByUserId]) {
                        const userName = await wamService.getUserName(updatedByUserId);
                        userIdToUserName[updatedByUserId] = `${userName.firstName} ${userName.lastName}`;
                    }
                };

                await getUserNames();

                // get all linked company names
                const getCompanyNames = async () => {
                    const eventLinkAttr = scheduleGroup.eventLinkAttr;
                    if (eventLinkAttr && eventLinkAttr['bbid']) {
                        const linkedBBIDs: string[] = eventLinkAttr['bbid'];
                        if (linkedBBIDs.length <= 20)
                            for (const bbid of linkedBBIDs) {
                                if (!eventLinkIdToCompanyName[bbid]) {
                                    const entity = await wamService.lookupEntityByBBID(bbid);
                                    eventLinkIdToCompanyName[bbid] = entity.label;
                                    companyNameToEventLinkId[entity.label] = bbid;
                                }
                            }
                    }
                };

                await getCompanyNames();
            }
        };
    }, []);

    const handleSearch = () => {
        if (searchBarInputValue) {
            setFilteredScheduleGroups(
                scheduleGroups.filter((group) => {
                    // condition 1: if group name or id contains search term, return
                    if (group.groupName.toLowerCase().includes(searchBarInputValue.toLocaleLowerCase())) {
                        return true;
                    }
                    if (group.groupId.toLowerCase().includes(searchBarInputValue.toLocaleLowerCase())) {
                        return true;
                    }

                    // condition 2: if search term matches a company name and the group is linked to that company
                    for (const companyName of Object.keys(auxData.companyNameToEventLinkId)) {
                        if (companyName.toLocaleLowerCase().includes(searchBarInputValue.toLowerCase())) {
                            const testId = auxData.companyNameToEventLinkId[companyName];
                            if (group.eventLinkAttr && group.eventLinkAttr['bbid']) {
                                if (group.eventLinkAttr['bbid'].includes(testId)) {
                                    return true;
                                }
                            }
                        }
                    }

                    // condition 3: update / create name matched
                    if (
                        auxData.userIdToUserName[group.createdBy]
                            .toLowerCase()
                            .includes(searchBarInputValue.toLocaleLowerCase()) ||
                        auxData.userIdToUserName[group.updatedBy]
                            .toLowerCase()
                            .includes(searchBarInputValue.toLocaleLowerCase())
                    ) {
                        return true;
                    }

                    // condition 4: bbid matched
                    if (group.eventLinkAttr && group.eventLinkAttr['bbid']) {
                        if (group.eventLinkAttr['bbid'].includes(searchBarInputValue)) {
                            return true;
                        }
                    }

                    return false;
                })
            );
        }
    };

    const LoadingIndicator = (): JSX.Element => {
        return (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', margin: '32px' }}>
                <CircularProgress />
            </div>
        );
    };

    return (
        <Box display="flex" flexDirection="column" height="100vh">
            <Header subtitle="Schedule Groups" />
            {isLoading ? (
                <LoadingIndicator />
            ) : (
                <Box flexGrow={1} overflow="auto" marginLeft="48px" marginRight="48px" marginY="20px">
                    <Button
                        variant="outlined"
                        color="secondary"
                        disableRipple
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`/new-schedule-group`}
                        size="large"
                        sx={{ marginBottom: '16px' }}
                    >
                        Create a new Schedule Group
                    </Button>

                    <TextField
                        fullWidth
                        variant="outlined"
                        size="medium"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start" disablePointerEvents={!searchBarInputValue}>
                                    <Tooltip title="Search for Schedule Groups">
                                        <span>
                                            <IconButton onClick={() => handleSearch()} disabled={!searchBarInputValue}>
                                                <SearchIcon />
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                </InputAdornment>
                            ),
                        }}
                        placeholder="Search for schedule groups"
                        label="Search for schedule groups by group ID / group name / BBID / company name / creator / updater (case insensitive)"
                        autoComplete="off"
                        value={searchBarInputValue}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setSearchBarInputValue(event.target.value);
                            if (event.target.value === '') {
                                setFilteredScheduleGroups(scheduleGroups);
                            }
                        }}
                        onKeyDown={(e: React.KeyboardEvent<HTMLElement>): void => {
                            if (e.key === 'Enter' && searchBarInputValue) {
                                e.preventDefault();
                                handleSearch();
                            }
                        }}
                        sx={{ paddingRight: '16px' }}
                    />

                    {filteredScheduleGroups.map((group) => (
                        <div
                            key={group.groupId}
                            style={{ marginTop: '16px', marginBottom: '16px', marginRight: '16px' }}
                        >
                            <Card variant="outlined">
                                <CardContent>
                                    <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14 }}>
                                        Group <span style={{ fontStyle: 'italic' }}>{group.groupId}</span>
                                    </Typography>
                                    <Typography variant="h5" component="div">
                                        {group.groupName}
                                    </Typography>
                                    <Typography sx={{ color: 'text.secondary', mb: 1.5 }}>
                                        Created on {formatTimestamp(new Date(group.created || ''))} by{' '}
                                        {auxData.userIdToUserName[group.createdBy]} {',  '} Updated on{' '}
                                        {formatTimestamp(new Date(group.updated || ''))} by{' '}
                                        {auxData.userIdToUserName[group.updatedBy]}
                                    </Typography>
                                    <Typography variant="body1">Linked Companies:</Typography>
                                    {group.eventLinkAttr['bbid'] && (
                                        <Grid container rowSpacing={1} columnSpacing={1} sx={{ marginTop: '4px' }}>
                                            {group.eventLinkAttr['bbid'].slice(0, 20).map((bbid: string) => (
                                                <Grid item key={bbid}>
                                                    <Chip
                                                        label={
                                                            <p style={{ fontSize: '1rem' }}>
                                                                {auxData.eventLinkIdToCompanyName[bbid]
                                                                    ? `${auxData.eventLinkIdToCompanyName[bbid]}: ${bbid}`
                                                                    : `BBID: ${bbid}`}
                                                            </p>
                                                        }
                                                    />
                                                </Grid>
                                            ))}
                                            {group.eventLinkAttr['bbid'].length > 20 && (
                                                <Grid item>
                                                    <Chip
                                                        label={
                                                            <p style={{ fontSize: '1rem' }}>
                                                                +{group.eventLinkAttr['bbid'].length - 20} more
                                                            </p>
                                                        }
                                                    />
                                                </Grid>
                                            )}
                                        </Grid>
                                    )}
                                </CardContent>
                                <CardActions>
                                <Button
                                        size="large"
                                        variant="outlined"
                                        disableRipple
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={`/schedule-group/${group.groupId}`}
                                    >
                                        View
                                    </Button>
                                    <Button
                                        size="large"
                                        variant="outlined"
                                        color='secondary'
                                        disableRipple
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={`/edit-schedule-group/${group.groupId}`}
                                    >
                                        Edit
                                    </Button>
                                </CardActions>
                            </Card>
                        </div>
                    ))}
                </Box>
            )}
        </Box>
    );
};

export default ScheduleGroups;
