/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
    Box,
    Breakpoint,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Tooltip,
} from '@mui/material';
import { Delete } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { MRT_Row, MRT_RowModel, MRT_TableOptions } from 'material-react-table';

import { Entity, JobLinkIdType } from '../../types/schedule/jobLinkID';
import PaginatedTable from '../PaginatedTable/PaginatedTable';
import { useScheduleGroupContext } from '../../context/scheduleGroup';
import {
    formatTimestamp,
    groupJobConfigsByIri,
    processJobConfigSearchResult,
    searchJobConfigs,
    searchJobConfigsByIris,
} from '../../utils';
import wamService from '../../services/wamService';
import JobConfigSearchResultTable from './JobConfigSearchResultTable';
import { JobConfig } from 'src/types/schedule/scheduleGroup';

const useStyles = makeStyles(() => {
    return {
        tableContainer: {
            marginTop: '16px',
            marginBottom: '16px',
        },
    };
});
interface LinkedIdsTableProps {
    jobLinkIdType: JobLinkIdType;
    viewOnly: boolean;
}

const LinkedIdsTable = (props: LinkedIdsTableProps): JSX.Element => {
    const classes = useStyles();
    const scheduleGroupContext = useScheduleGroupContext();

    const { groupData, setGroupData, removeLinkedId, setJobConfigSearchInputValue } = scheduleGroupContext;

    const { linkedEntities, members } = groupData;

    const { jobLinkIdType, viewOnly } = props;

    const columns = [
        {
            header: '',
            size: 5,
            id: 'remove-button',
            enableColumnFilter: false,
            enableSorting: false,
            enableColumnActions: false,
            Cell: ({ row }: { row: MRT_Row<Entity> }) => (
                <Tooltip title={viewOnly ? 'View Only Mode' : 'Remove from Group'}>
                    <span>
                        <IconButton
                            color="secondary"
                            disableRipple
                            disabled={viewOnly}
                            onClick={() => {
                                removeLinkedId(row.original.entityBBID);
                            }}
                        >
                            <Delete />
                        </IconButton>
                    </span>
                </Tooltip>
            ),
        },
        {
            header: jobLinkIdType,
            size: 40,
            accessorKey: 'entityBBID',
        },
        {
            header: 'Entity Name',
            accessorKey: 'entityName',
        },
        {
            header: 'Entity Instance IRI',
            accessorKey: 'entityInstanceIRI',
            Cell: ({ row }: { row: MRT_Row<Entity> }) => (
                <a href={row.original.entityInstanceIriURL} target="_blank" rel="noopener noreferrer">
                    {row.original.entityInstanceIRI}
                </a>
            ),
        },
        {
            header: 'Next Event',
            Cell: ({ row }: { row: MRT_Row<Entity> }) =>
                row.original.nextEvent && (
                    <>
                        <span>
                            {row.original.nextEvent.eventBlob ? row.original.nextEvent.eventBlob.eventDescription : ''}
                        </span>
                        ,{' '}
                        <Tooltip
                            title={formatTimestamp(new Date(row.original.nextEvent.eventStartDate))}
                            placement="bottom"
                        >
                            <Box component="span" sx={{ textDecoration: 'underline', textUnderlineOffset: '2px' }}>
                                {row.original.nextEvent.eventStartDate}
                            </Box>
                        </Tooltip>{' '}
                        {row.original.nextEvent.eventEndDate && (
                            <>
                                -{' '}
                                <Tooltip
                                    title={formatTimestamp(new Date(row.original.nextEvent.eventEndDate))}
                                    placement="bottom"
                                >
                                    <Box
                                        component="span"
                                        sx={{ textDecoration: 'underline', textUnderlineOffset: '2px' }}
                                    >
                                        {row.original.nextEvent.eventEndDate}
                                    </Box>
                                </Tooltip>
                            </>
                        )}
                    </>
                ),
        },
        {
            header: '',
            size: 30,
            id: 'missing-jobs-warning',
            enableColumnFilter: false,
            enableSorting: false,
            enableColumnActions: false,
            muiTableBodyCellProps: {
                align: 'left',
            },
            Cell: ({ row }: { row: MRT_Row<Entity> }) => {
                if (row.original.missingJobConfigIds.length) {
                    return (
                        <Tooltip title={`Some jobs for ${row.original.entityName} are missing from this group.`}>
                            <div style={{ color: 'red' }}>Missing Jobs</div>
                        </Tooltip>
                    );
                } else {
                    return null;
                }
            },
        },
        {
            header: '',
            size: 30,
            id: 'add-jobs-button',
            enableColumnFilter: false,
            enableSorting: false,
            enableColumnActions: false,
            muiTableBodyCellProps: {
                align: 'center',
            },
            Cell: ({ row }: { row: MRT_Row<Entity> }) => (
                <Tooltip title={viewOnly ? 'View Only Mode' : `Update group jobs for ${row.original.entityName}`}>
                    <span>
                        <Button
                            variant="outlined"
                            color="primary"
                            disableRipple
                            disabled={viewOnly}
                            onClick={() => {
                                setJobConfigSearchInputValue(row.original.entityInstanceIRI || '');
                            }}
                        >
                            Add Jobs
                        </Button>
                    </span>
                </Tooltip>
            ),
        },
        {
            header: '',
            size: 30,
            id: 'remove-jobs-button',
            enableColumnFilter: false,
            enableSorting: false,
            enableColumnActions: false,
            muiTableBodyCellProps: {
                align: 'center',
            },
            Cell: ({ row }: { row: MRT_Row<Entity> }) => (
                <Tooltip title={viewOnly ? 'View Only Mode' : `Remove linked jobs for ${row.original.entityName}`}>
                    <span>
                        <Button
                            variant="outlined"
                            color="error"
                            disableRipple
                            disabled={viewOnly}
                            onClick={() => handleRemoveJobsClick(row.original)}
                        >
                            Remove Jobs
                        </Button>
                    </span>
                </Tooltip>
            ),
        },
        {
            header: '',
            size: 30,
            id: 'view-events-button',
            enableColumnFilter: false,
            enableSorting: false,
            enableColumnActions: false,
            muiTableBodyCellProps: {
                align: 'center',
            },
            Cell: ({ row }: { row: MRT_Row<Entity> }) => (
                <Tooltip title="View all Events for this Entity">
                    <Button
                        variant="outlined"
                        color="success"
                        disableRipple
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`/events-list?jobLinkIdType=bbid&jobLinkIdValue=${row.original.entityBBID}`}
                    >
                        View Events
                    </Button>
                </Tooltip>
            ),
        },
    ];

    const [linkedIdsTableRowModel, setLinkedIdsTableRowModel] = useState<MRT_RowModel<Entity>>();
    const [isLinkedIdsTableBusy, setIsLinkedIdsTableBusy] = useState(false);

    const [entityIdsFetchedNextEvents, setEntityIdsFetchedNextEvents] = useState<Set<string>>(new Set());
    const [entityIdsCheckedMissingJobs, setEntityIdsCheckedMissingJobs] = useState<Set<string>>(new Set());

    const [openRemoveJobsDialog, setOpenRemoveJobsDialog] = useState(false);
    const [entityToRemoveJobs, setEntityToRemoveJobs] = useState<Entity | null>(null);
    const [isLoadingAllJobConfigsForClickedEntity, setIsLoadingAllJobConfigsForClickedEntity] = useState(false);
    const [allJobConfigsForClickedEntity, setAllJobConfigsForClickedEntity] = useState<JobConfig[]>([]);
    const [selectedJobConfigsToRemove, setSelectedJobConfigsToRemove] = useState<JobConfig[]>([]);
    const jobConfigsForRemove = allJobConfigsForClickedEntity.filter((jobConfig) =>
        members.find((member) => jobConfig.jobConfigId === member.jobConfigId)
    );

    const loadJobsForClickedEntity = async (iri: string) => {
        setIsLoadingAllJobConfigsForClickedEntity(true);
        const searchResult = await searchJobConfigs(iri);
        setAllJobConfigsForClickedEntity(
            processJobConfigSearchResult(searchResult).filter((jobConfig) => jobConfig.iri === iri)
        );
        setIsLoadingAllJobConfigsForClickedEntity(false);
    };

    const handleRemoveJobsClick = (entityToRemoveJobs: Entity) => {
        if (entityToRemoveJobs.entityInstanceIRI) {
            setEntityToRemoveJobs(entityToRemoveJobs);
            loadJobsForClickedEntity(entityToRemoveJobs.entityInstanceIRI);
            setOpenRemoveJobsDialog(true);
        }
    };

    const removeJobsFromGroup = () => {
        setGroupData((groupData) => {
            const updatedMembers = groupData.members.filter(
                (member) =>
                    !selectedJobConfigsToRemove.find((jobConfig) => jobConfig.jobConfigId === member.jobConfigId)
            );
            return {
                ...groupData,
                members: updatedMembers,
            };
        });
        setOpenRemoveJobsDialog(false);
    };

    const checkMissingJobsForIris = async (iris: string[], updatedLinkedEntities: Entity[]): Promise<boolean> => {
        const searchResult = await searchJobConfigsByIris(iris);
        const allJobConfigs = processJobConfigSearchResult(searchResult);
        const groupedJobConfigs = groupJobConfigsByIri(allJobConfigs);
        let updated = false;
        for (const [iri, jobConfigs] of Object.entries(groupedJobConfigs)) {
            const missingJobConfigIds = jobConfigs
                .filter(
                    (jobConfig) =>
                        jobConfig.iri === iri &&
                        !groupData.members.find((member) => member.jobConfigId === jobConfig.jobConfigId)
                )
                .map((jobConfig) => jobConfig.jobConfigId);
            const index = updatedLinkedEntities.findIndex((entity) => entity.entityInstanceIRI === iri);
            if (index === -1) {
                continue;
            }
            updatedLinkedEntities[index].missingJobConfigIds = missingJobConfigIds;
            updated = true;
        }
        return updated;
    };

    useEffect(() => {
        if (!linkedIdsTableRowModel) {
            return;
        }
        const displayedEntities = linkedIdsTableRowModel.rows.map((row) => row.original);
        if (displayedEntities) {
            const entityIds = displayedEntities.map((entity) => entity.entityBBID);
            const entityIdsToFetchNextEvents = entityIds.filter(
                (entityId) => !entityIdsFetchedNextEvents.has(entityId)
            );
            const entityIris = displayedEntities
                .map((entity) => entity.entityInstanceIRI)
                .filter((iri) => iri !== undefined);
            const entityIrisToCheckMissingJobs = entityIris.filter(
                (entityIri) => !entityIdsCheckedMissingJobs.has(entityIri)
            );

            let updated = false;

            const fetchNextEventsAndCheckMissingJobs = async () => {
                setIsLinkedIdsTableBusy(true);
                const updatedLinkedEntities = [...groupData.linkedEntities];

                if (entityIdsToFetchNextEvents.length > 0) {
                    const nextEvents = await wamService.getNextEventListByBBIDs(entityIdsToFetchNextEvents);
                    if (nextEvents) {
                        for (const nextEvent of nextEvents) {
                            const index = updatedLinkedEntities.findIndex(
                                (entity) => entity.entityBBID === nextEvent?.jobLinkIdValue
                            );
                            if (index !== -1) {
                                updatedLinkedEntities[index].nextEvent = nextEvent;
                            }
                        }
                        updated = true;
                    }
                }

                if (entityIrisToCheckMissingJobs.length > 0) {
                    updated = await checkMissingJobsForIris(entityIrisToCheckMissingJobs, updatedLinkedEntities);
                }

                if (updated) {
                    setEntityIdsCheckedMissingJobs(
                        new Set([...entityIdsCheckedMissingJobs, ...entityIrisToCheckMissingJobs])
                    );
                    setEntityIdsFetchedNextEvents(
                        new Set([...entityIdsFetchedNextEvents, ...entityIdsToFetchNextEvents])
                    );
                    setGroupData({
                        ...groupData,
                        linkedEntities: updatedLinkedEntities,
                    });
                }

                setIsLinkedIdsTableBusy(false);
            };
            fetchNextEventsAndCheckMissingJobs();
        }
    }, [linkedIdsTableRowModel]);

    useEffect(() => {
        if (!linkedIdsTableRowModel) {
            return;
        }
        const displayedEntities = linkedIdsTableRowModel.rows.map((row) => row.original);
        const entityIris = displayedEntities
            .map((entity) => entity.entityInstanceIRI)
            .filter((iri) => iri !== undefined);
        const updatedLinkedEntities = [...groupData.linkedEntities];
        const updateMissingJobsWarnings = async () => {
            setIsLinkedIdsTableBusy(true);
            const updated = await checkMissingJobsForIris(entityIris, updatedLinkedEntities);
            if (updated) {
                setGroupData({
                    ...groupData,
                    linkedEntities: updatedLinkedEntities,
                });
            }
            setIsLinkedIdsTableBusy(false);
        };
        updateMissingJobsWarnings();
    }, [members]);

    return (
        <>
            <div className={classes.tableContainer}>
                <PaginatedTable
                    // @ts-expect-error: TypeScript gives false type error
                    columns={columns}
                    rows={linkedEntities}
                    optionOverrides={
                        {
                            enableColumnResizing: false,
                            autoResetPageIndex: false,
                        } as MRT_TableOptions<Entity>
                    }
                    isLinkedIdsTableBusy={isLinkedIdsTableBusy}
                    setRowModel={setLinkedIdsTableRowModel}
                ></PaginatedTable>
            </div>
            <Dialog
                maxWidth={'95vw' as Breakpoint}
                fullWidth
                open={openRemoveJobsDialog}
                onClose={() => setOpenRemoveJobsDialog(false)}
            >
                <DialogTitle id="confirm-remove-dialog-title">
                    Deleting Linked Jobs for {entityToRemoveJobs?.entityName}
                </DialogTitle>
                <DialogContent>
                    <JobConfigSearchResultTable
                        jobConfigSearchResult={jobConfigsForRemove}
                        selectedJobConfigs={[]}
                        handleSearchResultSelection={function (selected: JobConfig[]): void {
                            setSelectedJobConfigsToRemove(selected);
                        }}
                        isBusy={isLoadingAllJobConfigsForClickedEntity}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenRemoveJobsDialog(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={removeJobsFromGroup} color="error" autoFocus>
                        Remove from Group
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default React.memo(LinkedIdsTable);
