import React from 'react';
import { IconButton, Tooltip, Box, Tab, Tabs, Paper } from '@mui/material';
import { Sort } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

import {
    EventScheduleTemplate,
    EventScheduleTemplateIntervalOnlyStep,
    EventScheduleTemplateWindowStep,
} from '../../types/schedule/scheduleTemplate';
import { TimeConversionRate } from '../../shared/constants';

import ScheduleTemplateInputGroup from './ScheduleTemplateInputGroup';
import ScheduleTemplateIntervalOnlyStepInput from './ScheduleTemplateIntervalOnlyStep';
import { useScheduleGroupContext } from '../../context/scheduleGroup';

const useStyles = makeStyles(() => {
    return {
        templateSectionContainer: {
            marginTop: '4px',
            paddingTop: '32px',
            paddingBottom: '32px',
            marginLeft: '16px',
            marginRight: '16px',
        },
        header: {
            width: '100%',
            textAlign: 'center',
            fontSize: '1.5rem',
            fontWeight: 400,
        },
        templateSectionTitle: {
            width: '100%',
            fontSize: '1.5rem',
            fontWeight: 400,
            marginBottom: '12px',
        },
    };
});

interface ScheduleTemplateSectionProps {
    viewOnly: boolean
}

const ScheduleTemplateSection = (props: ScheduleTemplateSectionProps): JSX.Element => {
    const classes = useStyles();
    const scheduleGroupContext = useScheduleGroupContext();

    const { groupData, setGroupData } = scheduleGroupContext;
    const { template } = groupData;

    const setScheduleTemplate = (template: EventScheduleTemplate) => {
        setGroupData((groupData) => ({
            ...groupData,
            template,
        }));
    };

    const getScheduleSteps = (scheduleType: 'datetime' | 'datetimeRange', phase: 'beforeRelease' | 'afterRelease') => {
        return template[scheduleType][phase].intervals;
    };
    const setScheduleSteps = (
        scheduleType: 'datetime' | 'datetimeRange',
        phase: 'beforeRelease' | 'afterRelease',
        newScheduleSteps: EventScheduleTemplateWindowStep[]
    ) => {
        const newScheduleTemplate = { ...template };
        newScheduleTemplate[scheduleType][phase].intervals = newScheduleSteps;
        setScheduleTemplate(newScheduleTemplate);
    };

    const handleSort = (scheduleType: 'datetime' | 'datetimeRange', phase: 'beforeRelease' | 'afterRelease') => {
        const sortComparator = (stepA: EventScheduleTemplateWindowStep, stepB: EventScheduleTemplateWindowStep) =>
            stepA.startTimeOffsetValue * TimeConversionRate.toMilliseconds[stepA.startTimeOffsetUnit] -
            stepB.startTimeOffsetValue * TimeConversionRate.toMilliseconds[stepB.startTimeOffsetUnit];

        const stepsToSort = template[scheduleType][phase].intervals;
        const sortedSteps = stepsToSort.sort(sortComparator);

        const newScheduleTemplate = { ...template };
        newScheduleTemplate[scheduleType][phase].intervals = sortedSteps;
        setScheduleTemplate(newScheduleTemplate);
    };

    const setDuringReleaseStep = (duringReleaseStep: EventScheduleTemplateIntervalOnlyStep) => {
        const newScheduleTemplate = { ...template };
        newScheduleTemplate.datetimeRange.duringRelease = duringReleaseStep;
        setScheduleTemplate(newScheduleTemplate);
    };

    const [activeTabIndex, setActiveTabIndex] = React.useState(0);

    const handleTabSwitch = (_event: React.SyntheticEvent, newActiveIndex: number) => {
        setActiveTabIndex(newActiveIndex);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={activeTabIndex} onChange={handleTabSwitch} aria-label="basic tabs example">
                    <Tab label="Datetime Schedule" disableRipple />
                    <Tab label="Datetime Range Schedule" disableRipple />
                </Tabs>
            </Box>
            <Paper>
                <div className={classes.templateSectionContainer} hidden={activeTabIndex !== 0}>
                    <div className={classes.templateSectionTitle}>
                        Before Release{' '}
                        <Tooltip title="Sort Rows">
                            <IconButton
                                onClick={() => {
                                    handleSort('datetime', 'beforeRelease');
                                }}
                            >
                                <Sort />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <ScheduleTemplateInputGroup
                        phaseType="beforeRelease"
                        templateSteps={getScheduleSteps('datetime', 'beforeRelease')}
                        setTemplateSteps={(steps) => setScheduleSteps('datetime', 'beforeRelease', steps)}
                        viewOnly={props.viewOnly}
                    ></ScheduleTemplateInputGroup>
                    <div className={classes.templateSectionTitle}>
                        After Release{' '}
                        <Tooltip title="Sort Rows">
                            <IconButton
                                onClick={() => {
                                    handleSort('datetime', 'afterRelease');
                                }}
                            >
                                <Sort />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <ScheduleTemplateInputGroup
                        phaseType="afterRelease"
                        templateSteps={getScheduleSteps('datetime', 'afterRelease')}
                        setTemplateSteps={(steps) => setScheduleSteps('datetime', 'afterRelease', steps)}
                        viewOnly={props.viewOnly}
                    ></ScheduleTemplateInputGroup>
                </div>
            </Paper>
            <Paper>
                <div className={classes.templateSectionContainer} hidden={activeTabIndex !== 1}>
                    <div className={classes.templateSectionTitle}>
                        Before Release{' '}
                        <Tooltip title="Sort Rows">
                            <IconButton
                                onClick={() => {
                                    handleSort('datetimeRange', 'beforeRelease');
                                }}
                            >
                                <Sort />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <ScheduleTemplateInputGroup
                        phaseType="beforeRelease"
                        templateSteps={getScheduleSteps('datetimeRange', 'beforeRelease')}
                        setTemplateSteps={(steps) => setScheduleSteps('datetimeRange', 'beforeRelease', steps)}
                        viewOnly={props.viewOnly}
                    ></ScheduleTemplateInputGroup>
                    <div className={classes.templateSectionTitle}>During Release</div>
                    <ScheduleTemplateIntervalOnlyStepInput
                        duringReleaseStep={template.datetimeRange.duringRelease}
                        setDuringReleaseStep={setDuringReleaseStep}
                        viewOnly
                    ></ScheduleTemplateIntervalOnlyStepInput>
                    <div className={classes.templateSectionTitle}>
                        After Release{' '}
                        <Tooltip title="Sort Rows">
                            <IconButton
                                onClick={() => {
                                    handleSort('datetimeRange', 'afterRelease');
                                }}
                            >
                                <Sort />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <ScheduleTemplateInputGroup
                        phaseType="afterRelease"
                        templateSteps={getScheduleSteps('datetimeRange', 'afterRelease')}
                        setTemplateSteps={(steps) => setScheduleSteps('datetimeRange', 'afterRelease', steps)}
                        viewOnly={props.viewOnly}
                    ></ScheduleTemplateInputGroup>
                </div>
            </Paper>
        </Box>
    );
};

export default ScheduleTemplateSection;
