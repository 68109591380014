import React from 'react';
import { Box, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { JobErrorSearchDocRaw, JobErrorSearchDoc } from '@webacq/wa-shared-definitions';
import HumioLink from '../HumioLink';
import PaginatedTable from '../../PaginatedTable/PaginatedTable';
import { PaginatedTableColumn } from '../../PaginatedTable/types';
import {
    MRT_ColumnFiltersState,
    MRT_PaginationState,
    MRT_Row,
    MRT_SortingState,
} from 'material-react-table';
import { usePaginatedQuery } from '../../PaginatedTable/usePaginatedQuery';
import { DocTypes } from '../../../shared/constants';
import { formatTimestamp } from '../../../utils';
import RunTreeLink from '../RunTreeLink';

const useStyles = makeStyles((theme: Theme) => ({
    contentBox: {
        paddingLeft: theme.spacing(4),
    },
}));

interface JobErrorsTableProps {
    jobConfigId: string;
    jobMode?: string;
    getRecordCount?: (count: number) => void;
}

const JobErrorsTable = (props: JobErrorsTableProps): JSX.Element => {
    const classes = useStyles();

    const [filters, setFilters] = React.useState<MRT_ColumnFiltersState>([]);
    const [sorting, setSorting] = React.useState<MRT_SortingState>([{ id: 'jobStartTime', desc: true }]);
    const [pagination, setPagination] = React.useState<MRT_PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    });

    const query = `${props.jobConfigId} ${props.jobMode ? 'AND job_mode_s:' + props.jobMode : ''}`;

    const {
        data: rows,
        numFound,
        noDataAvailable,
        isBusy,
    } = usePaginatedQuery<JobErrorSearchDocRaw, JobErrorSearchDoc>(
        query,
        DocTypes.JOB_ERR,
        filters,
        sorting,
        pagination
    );

    if (props.getRecordCount) {
        props.getRecordCount(numFound);
    }

    const columns = [
        {
            header: 'Config Version',
            accessorKey: 'jobConfigVersion',
        },
        {
            header: 'Mode',
            accessorKey: 'jobMode',
        },
        {
            header: 'Location',
            accessorKey: 'jobRunLocation',
        },
        {
            header: 'BBDS Transaction Id',
            accessorKey: 'bbdsTranId',
        },
        {
            header: 'Ran',
            accessorKey: 'jobStartTime',
            filterVariant: 'date',
            accessorFn: (rowData) => formatTimestamp(rowData.bbdsPublish),
        },
        {
            header: 'Errors',
            accessorKey: 'errMsg',
            accessorFn: (rowData) => (rowData.errMsg || []).map((errMsg, idx) => <div key={idx}>{errMsg}</div>),
        },
        {
            header: 'Run Tree',
            id: 'runTree',
            size: 20,
            enableSorting: false,
            enableColumnFilter: false,
            accessorFn: (rowData) => rowData.bbdsTranId,
            muiTableBodyCellProps: {
                sx: {
                    textAlign: 'center',
                    padding: '4px',
                },
            },
            Cell: ({  row }: { row: MRT_Row<JobErrorSearchDoc> }) => (
                <RunTreeLink id={row.original.bbdsTranId || ''} docType="JOB_ERR" />
            ),
        },
        {
            header: 'Humio',
            id: 'humioLink',
            accessorFn: (rowData) => (
                <HumioLink
                    logConfigName="crawlWorker"
                    query={rowData.runId || ''}
                    startTime={rowData.jobStartTime}
                    logRetentionInDays={30}
                />
            ),
            enableSorting: false,
            enableColumnFilter: false,
        },
    ] as PaginatedTableColumn<JobErrorSearchDoc>[];

    return (
        <>
            <Box hidden={noDataAvailable}>
                <Typography variant="subtitle1" color="secondary" gutterBottom>
                    &nbsp;&nbsp;Job Errors
                </Typography>
                <PaginatedTable
                    columns={columns}
                    rows={rows}
                    totalCount={numFound}
                    filters={filters}
                    sorting={sorting}
                    pagination={pagination}
                    onUpdateFilters={(filters) => {
                        setFilters(filters);
                        setPagination({ pageIndex: 0, pageSize: pagination.pageSize });
                    }}
                    onUpdateSorting={(sorting) => {
                        setSorting(sorting);
                        setPagination({ pageIndex: 0, pageSize: pagination.pageSize });
                    }}
                    onUpdatePagination={setPagination}
                    controlled={true}
                    isBusy={isBusy}
                    hideLastPageButton
                />
            </Box>

            <Box hidden={!noDataAvailable} className={classes.contentBox} fontStyle="italic">
                <Typography variant="body2">No job errors found</Typography>
            </Box>
        </>
    );
};

export default JobErrorsTable;
