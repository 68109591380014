import React from 'react';
import { makeStyles } from '@mui/styles';

import GroupMembersTable from './GroupMembersTable';
import { useScheduleGroupContext } from '../../context/scheduleGroup';

const useStyles = makeStyles(() => {
    return {
        italic: {
            fontStyle: 'italic',
        },
        groupMembersSectionContainer: {
            marginTop: '32px',
        },
        groupMembersTableContainer: {
            marginTop: '16px',
            marginBottom: '16px',
        },
        groupMembersTableHeading: {
            fontSize: '1.25rem',
            fontWeight: 700,
            marginBottom: '16px',
        },
    };
});

interface GroupMembersSectionProps {
    viewOnly: boolean;
}

const GroupMembersSection = (props: GroupMembersSectionProps): JSX.Element => {
    const classes = useStyles();
    const scheduleGroupContext = useScheduleGroupContext();

    const { viewOnly } = props;

    const { setGroupData } = scheduleGroupContext;

    const removeMemberFromGroupByJobConfigId = (jobConfigIdToRemove: string) => {
        setGroupData((groupData) => ({
            ...groupData,
            members: groupData.members.filter((member) => member.jobConfigId !== jobConfigIdToRemove),
        }));
    };

    return (
        <div className={classes.groupMembersSectionContainer}>
            <div className={classes.groupMembersTableContainer}>
                <GroupMembersTable
                    removeMemberFromGroupByJobConfigId={removeMemberFromGroupByJobConfigId}
                    viewOnly={viewOnly}
                />
            </div>
        </div>
    );
};

export default GroupMembersSection;
