import React from 'react';
import { Delete, AddCircleOutline } from '@mui/icons-material';
import {
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Stack,
    TextField,
    Select,
    MenuItem,
    IconButton,
    Button,
    Switch,
    SelectChangeEvent,
    FormControl,
    Tooltip,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import {
    ScheduleTemplateOffsetTimeUnit,
    ScheduleTemplateIntervalTimeUnit,
    EventScheduleTemplateWindowStep,
} from '../../types/schedule/scheduleTemplate';
import { MaximumNumberOfRows } from '../../shared/constants';
import {
    createPlaceholderScheduleTemplateRow,
    validateScheduleTemplateStepEndTime,
    validateScheduleTemplateStepIntervalValue,
    validateScheduleTemplateStepOffsetValue,
} from '../../utils';

const useStyles = makeStyles(() => {
    return {
        inputGroupContainer: {
            marginBottom: '32px',
        },
        noRowsDefinedLabel: {
            fontSize: '1rem',
            marginTop: '16px',
            marginBottom: '16px',
        },
    };
});

interface ScheduleTemplateInputGroupProps {
    phaseType: 'beforeRelease' | 'afterRelease';
    templateSteps: EventScheduleTemplateWindowStep[];
    setTemplateSteps: (steps: EventScheduleTemplateWindowStep[]) => void;
    viewOnly: boolean;
}

const ScheduleTemplateInputGroup = (props: ScheduleTemplateInputGroupProps): JSX.Element => {
    const classes = useStyles();

    const { phaseType, templateSteps, setTemplateSteps, viewOnly } = props;

    const handleChange = (stepIndex: number, changedField: string, newValue: number | string | boolean) => {
        const stepToUpdate = templateSteps[stepIndex];

        const updatedRow = {
            ...stepToUpdate,
            [changedField]: newValue,
        };

        setTemplateSteps([...templateSteps.slice(0, stepIndex), updatedRow, ...templateSteps.slice(stepIndex + 1)]);
    };

    const handleDeletion = (stepIndex: number) => {
        setTemplateSteps([...templateSteps.slice(0, stepIndex), ...templateSteps.slice(stepIndex + 1)]);
    };

    const handleCreation = () => {
        setTemplateSteps([...templateSteps, createPlaceholderScheduleTemplateRow()]);
    };

    return (
        <div className={classes.inputGroupContainer}>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Start Time Offset</TableCell>
                            <TableCell>End time Offset</TableCell>
                            <TableCell>Interval</TableCell>
                            <TableCell align="right" width="170px">
                                Allow Overlapped Run
                            </TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {templateSteps.map((step, index) => (
                            <TableRow key={index}>
                                <TableCell>
                                    <Stack direction="row" spacing={1}>
                                        <TextField
                                            disabled={viewOnly}
                                            fullWidth
                                            type="number"
                                            size="small"
                                            error={
                                                !validateScheduleTemplateStepOffsetValue(step.startTimeOffsetValue)
                                                    .isValid
                                            }
                                            helperText={
                                                <>
                                                    {
                                                        validateScheduleTemplateStepOffsetValue(
                                                            step.startTimeOffsetValue
                                                        ).helperText
                                                    }
                                                </>
                                            }
                                            InputProps={{
                                                inputProps: { min: 0 },
                                                style: { fontSize: '1rem' },
                                            }}
                                            value={step.startTimeOffsetValue}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                handleChange(index, 'startTimeOffsetValue', event.target.value);
                                            }}
                                        />
                                        <FormControl sx={{ minWidth: 150 }}>
                                            <Select
                                                disabled={viewOnly}
                                                fullWidth
                                                defaultValue={ScheduleTemplateOffsetTimeUnit.SECOND}
                                                value={step.startTimeOffsetUnit}
                                                onChange={(event: SelectChangeEvent) => {
                                                    handleChange(index, 'startTimeOffsetUnit', event.target.value);
                                                }}
                                                sx={{ height: '40px' }}
                                            >
                                                {Object.values(ScheduleTemplateOffsetTimeUnit).map((unit) => (
                                                    <MenuItem value={unit} key={unit}>
                                                        {Math.abs(step.startTimeOffsetValue) > 1 ? `${unit}s` : unit}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Stack>
                                </TableCell>
                                <TableCell>
                                    <Stack direction="row" spacing={1}>
                                        <TextField
                                            disabled={viewOnly}
                                            fullWidth
                                            type="number"
                                            size="small"
                                            error={
                                                !validateScheduleTemplateStepOffsetValue(step.endTimeOffsetValue)
                                                    .isValid ||
                                                !validateScheduleTemplateStepEndTime(step, phaseType).isValid
                                            }
                                            helperText={
                                                <>
                                                    <>
                                                        {
                                                            validateScheduleTemplateStepOffsetValue(
                                                                step.endTimeOffsetValue
                                                            ).helperText
                                                        }
                                                    </>
                                                    <>
                                                        {
                                                            validateScheduleTemplateStepEndTime(step, phaseType)
                                                                .helperText
                                                        }
                                                    </>
                                                </>
                                            }
                                            InputProps={{
                                                inputProps: { min: 0 },
                                                style: { fontSize: '1rem' },
                                            }}
                                            value={step.endTimeOffsetValue}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                handleChange(index, 'endTimeOffsetValue', event.target.value);
                                            }}
                                        />
                                        <FormControl sx={{ minWidth: 150 }}>
                                            <Select
                                                disabled={viewOnly}
                                                fullWidth
                                                defaultValue={ScheduleTemplateOffsetTimeUnit.SECOND}
                                                value={step.endTimeOffsetUnit}
                                                onChange={(event: SelectChangeEvent) => {
                                                    handleChange(index, 'endTimeOffsetUnit', event.target.value);
                                                }}
                                                sx={{ height: '40px' }}
                                            >
                                                {Object.values(ScheduleTemplateOffsetTimeUnit).map((unit) => (
                                                    <MenuItem value={unit} key={unit}>
                                                        {Math.abs(step.endTimeOffsetValue) > 1 ? `${unit}s` : unit}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Stack>
                                </TableCell>
                                <TableCell>
                                    <Stack direction="row" spacing={1}>
                                        <TextField
                                            disabled={viewOnly}
                                            fullWidth
                                            type="number"
                                            size="small"
                                            error={
                                                !validateScheduleTemplateStepIntervalValue(
                                                    step.intervalValue,
                                                    step.intervalUnit
                                                ).isValid
                                            }
                                            helperText={
                                                <>
                                                    {
                                                        validateScheduleTemplateStepIntervalValue(
                                                            step.intervalValue,
                                                            step.intervalUnit
                                                        ).helperText
                                                    }
                                                </>
                                            }
                                            InputProps={{ inputProps: { min: 0 }, style: { fontSize: '1rem' } }}
                                            value={step.intervalValue}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                handleChange(index, 'intervalValue', event.target.value);
                                            }}
                                        />
                                        <FormControl sx={{ minWidth: 150 }}>
                                            <Select
                                                disabled={viewOnly}
                                                fullWidth
                                                defaultValue={ScheduleTemplateIntervalTimeUnit.SECOND}
                                                value={step.intervalUnit}
                                                onChange={(event: SelectChangeEvent) => {
                                                    handleChange(index, 'intervalUnit', event.target.value);
                                                }}
                                                sx={{ height: '40px' }}
                                            >
                                                {Object.values(ScheduleTemplateIntervalTimeUnit).map((unit) => (
                                                    <MenuItem value={unit} key={unit}>
                                                        {Math.abs(step.intervalValue) > 1 ? `${unit}s` : unit}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Stack>
                                </TableCell>
                                <TableCell align="right">
                                    <Switch
                                        disabled={viewOnly}
                                        color="secondary"
                                        checked={step.allowOverlappedRun}
                                        onChange={() => {
                                            // we don't want to allow overlapped run at this time, turn the user back if they they click on the switch
                                            handleChange(index, 'allowOverlappedRun', !step.allowOverlappedRun);
                                            setTimeout(() => {
                                                alert('Allowing overlapped run is not currently supported.');
                                                handleChange(index, 'allowOverlappedRun', step.allowOverlappedRun);
                                            }, 0);
                                        }}
                                    ></Switch>
                                </TableCell>
                                <TableCell>
                                    <IconButton
                                        disabled={viewOnly}
                                        color="secondary"
                                        disableRipple
                                        onClick={() => {
                                            handleDeletion(index);
                                        }}
                                    >
                                        <Delete />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {templateSteps.length === 0 && <div className={classes.noRowsDefinedLabel}>No Rows Defined</div>}
                {!viewOnly && (
                    <Tooltip title={templateSteps.length >= 5 ? `${MaximumNumberOfRows} Rows Max` : 'Add Another Row'}>
                        <span style={{ width: '100%' }}>
                            <Button
                                variant="outlined"
                                color="success"
                                disableRipple
                                sx={{ width: '99%', marginTop: '8px' }}
                                onClick={handleCreation}
                                disabled={templateSteps.length >= MaximumNumberOfRows}
                            >
                                <AddCircleOutline></AddCircleOutline>
                            </Button>
                        </span>
                    </Tooltip>
                )}
            </div>
        </div>
    );
};

export default ScheduleTemplateInputGroup;
