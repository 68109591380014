/* eslint-disable @typescript-eslint/ban-types */
import React, { useMemo } from 'react';
import {
    MaterialReactTable,
    useMaterialReactTable,
    MRT_ColumnDef,
    MRT_TableOptions,
    MRT_ColumnFiltersState,
    MRT_PaginationState,
    MRT_SortingState,
    MRT_RowModel,
} from 'material-react-table';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { OnChangeFn } from '@tanstack/react-table';
import BusyIndicator from './BusyIndicator';

interface PaginatedTableProps<T extends object> {
    controlled?: boolean;
    columns: MRT_ColumnDef<T>[];
    filters?: MRT_ColumnFiltersState;
    sorting?: MRT_SortingState;
    initialSorting?: MRT_SortingState;
    pagination?: MRT_PaginationState;
    rows: T[];
    totalCount?: number;
    onUpdateFilters?: OnChangeFn<MRT_ColumnFiltersState>;
    onUpdateSorting?: OnChangeFn<MRT_SortingState>;
    onUpdatePagination?: OnChangeFn<MRT_PaginationState>;
    optionOverrides?: MRT_TableOptions<T>;
    isBusy?: boolean;
    hideLastPageButton?: boolean;
    setRowModel?: React.Dispatch<React.SetStateAction<MRT_RowModel<T> | undefined>>;
}

const PaginatedTable = <T extends object>({
    controlled = false,
    columns,
    rows,
    filters,
    sorting,
    initialSorting,
    pagination,
    onUpdateFilters,
    onUpdateSorting,
    onUpdatePagination,
    totalCount,
    optionOverrides,
    isBusy,
    hideLastPageButton,
    setRowModel,
}: PaginatedTableProps<T>): JSX.Element => {
    const tableColumns: MRT_ColumnDef<T>[] = useMemo(() => {
        return columns.map((column) => {
            const columnDef: Partial<MRT_ColumnDef<T>> = {};
            if (column.filterVariant?.includes('date')) {
                columnDef.Cell = ({ cell }) => cell.getValue<Date>().toLocaleString();
                columnDef.accessorFn = (row: T) => {
                    const val = row[(column.accessorKey || column.id) as keyof T] as string;
                    return new Date(val);
                };
            }
            return { ...columnDef, ...column };
        });
    }, []);

    let tableOptions = {
        columns: tableColumns,
        data: rows || [],
        enableRowSelection: false,
        layoutMode: 'semantic',
        columnFilterDisplayMode: 'popover',
        initialState: { density: 'compact', showColumnFilters: true },
        enableTopToolbar: false,
        enableColumnResizing: true,
        enableColumnPinning: true,
        muiTableBodyCellProps: {
            sx: {
                padding: '4px',
            },
        },
        muiTableHeadCellProps: {
            sx: (theme) => ({
                color: theme.palette.secondary.main,
            }),
        },
        muiTablePaperProps: {
            elevation: 4,
            variant: 'elevation',
            sx: {
                padding: '8px',
            },
        },
        muiPaginationProps: {
            showLastButton: !hideLastPageButton,
        },
    } as MRT_TableOptions<T>;

    if (initialSorting) {
        tableOptions.initialState = {
            ...tableOptions.initialState,
            sorting: initialSorting,
        };
    }

    if (controlled) {
        tableOptions = {
            ...tableOptions,
            manualFiltering: true,
            manualPagination: true,
            manualSorting: true,
            rowCount: totalCount || rows?.length,
            onColumnFiltersChange: onUpdateFilters,
            onSortingChange: onUpdateSorting,
            onPaginationChange: onUpdatePagination,
            state: {
                columnFilters: filters,
                sorting: sorting,
                pagination: pagination,
            },
        };
    }

    tableOptions = { ...tableOptions, ...optionOverrides };

    const table = useMaterialReactTable(tableOptions);

    const rowModel = table.getRowModel();

    if (setRowModel) {
        setRowModel(rowModel);
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <div style={{ position: 'relative' }}>
                {isBusy && <BusyIndicator />}
                <MaterialReactTable table={table} />
            </div>
        </LocalizationProvider>
    );
};

export default PaginatedTable;
